export function currentLearner (uid) {
  localStorage.setItem('CLID', uid)
}

export function setColors (target, distract) {
  localStorage.setItem('tColor', target)
  localStorage.setItem('dColor', distract)
}

export function getCurrentLearner () {
  return localStorage.getItem('CLID')
}

export function clearCurrentLearner () {
  localStorage.setItem('CLID', '')
  localStorage.setItem('pColor', '')
  localStorage.setItem('nColor', '')
}

export function setDictionary (data) {
  const dictionary = JSON.stringify(data)
  localStorage.setItem('LearnerDictionary', dictionary)
}

export function setImages (data) {
  const images = JSON.stringify(data)
  localStorage.setItem('LearnerDictionaryImages', images)
}
