<template>
  <v-app>
    <v-app-bar
      app
      clipped-left
      color="atBlue"
      dense
      dark
    >
      <v-app-bar-nav-icon @click.stop="dashNav = !dashNav" />
      <a
        href="#"
        class="text-decoration-none white--text"
      ><v-toolbar-title>e<span class="atYellow--text">|</span>portal</v-toolbar-title></a>
      <v-spacer />
      <user-menu
        v-if="isLoggedIn"
        :avatar="user.initials"
        :office="organization.name"
        :username="user.name"
      />
      <v-btn
        v-else
        small
        text
        :to="{ name: 'eportal-login'}"
      >
        Log in
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="dashNav"
      app
      clipped
      fixed
    >
      <v-list>
        <v-list-item-group
          v-for="(item, i) in dashboardNavigation"
          :key="i"
          color="atBlue"
        >
          <v-list-item
            :to="{ name: item.route }"
            link
            exact
          >
            <v-list-item-action>
              <v-icon :color="item.color">
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <back-to-top />
    <big-footer />
    <global-snackbar />
  </v-app>
</template>

<script>
import BackToTop from '../components/layout/BackToTop'
import BigFooter from '../components/layout/BigFooter'
import UserMenu from '../components/layout/AdminUserMenu'
import UserHelpers from '@/shared/mixins/userHelpers.js'
import GlobalSnackbar from './GlobalSnackbar.vue'
export default {
  name: 'AdminLayout',
  components: {
    BackToTop,
    BigFooter,
    GlobalSnackbar,
    UserMenu
  },
  mixins: [UserHelpers],
  data () {
    return {
      dashNav: false,
      dashboardNavigationItems: [
        {
          route: 'eportal-dashboard',
          icon: 'mdi-shield-key',
          color: 'atBlue',
          title: 'Admin',
          permission: 0
        },
        {
          route: 'eportal-dashboard',
          icon: 'mdi-view-dashboard',
          color: 'atBlue',
          title: 'Dashboard',
          permission: 2
        },
        {
          route: 'eportal-statistics',
          icon: 'mdi-chart-histogram',
          color: 'atBlue',
          title: 'Statistics',
          permission: 0
        },
        {
          route: 'eportal-forms-list',
          icon: 'mdi-form-select',
          color: 'atBlue',
          title: 'Forms',
          permission: 2
        },
        {
          route: 'eportal-reports-list',
          icon: 'mdi-file-multiple',
          color: 'atBlue',
          title: 'Reports',
          permission: 2
        }
      ],
      isLoggedIn: false,
      organization: {},
      user: {},
      userAvatar: ''
    }
  },
  computed: {
    dashboardNavigation () {
      const result = this.dashboardNavigationItems.filter(item => item.permission >= this.permission)
      return result
    }
  },
  created () {
    if (localStorage.isLoggedIn) {
      this.isLoggedIn = localStorage.isLoggedIn
    }
    if (localStorage.user) {
      this.user = JSON.parse(localStorage.user)
    }
    if (localStorage.organization) {
      this.organization = JSON.parse(localStorage.organization)
    }
  },
  beforeDestroy () {

  },
  mounted () {
    this.initDashNav()
  },
  methods: {
    initDashNav () {
      this.dashNav = window.innerWidth > 1264
    }
  }
}
</script>

<style scoped>

</style>
