import { isLoggedIn } from '@/shared/utils/auth'
import LENUM from '@/shared/enums/LocalLoad'
import axios from 'axios'

const state = {
  apiState: LENUM.INIT,
  apiErrors: null,
  currentOrganization: null,
  allMembers: [],
  currentMember: {},
  currentMemberPermissions: {}
}

const getters = {
  apiState: state => state.apiState,
  apiErrors: state => state.apiErrors,
  currentOrganization: state => state.currentOrganization,
  allMembers: state => state.allMembers,
  currentMember: state => state.currentMember,
  currentMemberPermissions: state => state.currentMemberPermissions
}

const actions = {
  async fetchOrganization ({ commit, state }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        if (state.currentOrganization === null) {
          const response = await axios.get('/api/v1/organization')
          commit('setOrganization', response.data)
        }
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async fetchOrganizationMembers ({ commit, dispatch, state }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        if (state.currentOrganization === null) {
          await dispatch('fetchOrganization')
        }
        if (state.allMembers.length === 0) {
          const response = await axios.get('/api/v1/organization/members')
          commit('setOrganizationMembers', response.data)
        }
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async setCurrentMember ({ commit, dispatch, state }, id) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        if (state.allMembers.length === 0) {
          await dispatch('fetchOrganizationMembers')
        }
        commit('setCurrentMember', id)
        const permissions = await axios.get(`/api/v1/member/${id}/permissions`)
        commit('setCurrentMemberPermissions', permissions.data)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async changeMemberPermissions ({ commit, dispatch, getters, rootGetters }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        if (getters.currentMemberPermissions === {}) {
          await dispatch('setCurrentMember', data.member_id)
        }
        await axios.patch(`/api/v1/member/${data.member_id}/change-permissions`, data)
        if (rootGetters['user/userDetails'].id === data.member_id) {
          dispatch('user/changeUserPermission', data, { root: true })
        }
        commit('changeMemberPermission', data)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-information',
          text: `Success: Permissions changed for ${getters.currentMember.name}.`,
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to change member permissions.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async changeMemberDetails ({ commit, dispatch, getters }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.patch(`/api/v1/organization/users/${data.user_id}`, data)
        const payload = {
          new: data,
          current: getters.currentMember
        }
        commit('changeMemberDetails', payload)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Member details updated.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        console.log(error)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to change member details.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async removeMemberFromOrganization ({ commit, dispatch }, payload) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.post(`/api/v1/organization/users/${payload.member_id}/remove`)
        commit('removeMemberFromOrganization', payload.member_id)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Member removed.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        console.log(error)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to remove member.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  }
}

const mutations = {
  setApiState (state, payload) {
    state.apiState = payload
  },
  setOrganization (state, payload) {
    state.currentOrganization = payload
  },
  setOrganizationMembers (state, payload) {
    state.allMembers = payload
  },
  setCurrentMember (state, id) {
    const currentMember = state.allMembers.filter((member) => member.id === id)[0]
    state.currentMember = currentMember
  },
  setCurrentMemberPermissions (state, payload) {
    state.currentMemberPermissions = payload
  },
  changeMemberPermission (state, data) {
    state.currentMemberPermissions = data.permissions
  },
  changeMemberDetails (state, data) {
    const index = state.allMembers.findIndex(member => member.id === data.new.user_id)
    const user = {
      id: data.current.id,
      name: data.current.name,
      role: data.new.new_role ? data.new.new_role : data.current.role,
      title: data.new.new_title ? data.new.new_title : data.cuurent.title
    }
    state.allMembers.splice(index, 1, user)
  },
  removeMemberFromOrganization (state, payload) {
    const index = state.allMembers.findIndex(member => member.id === payload)
    if (index !== -1) {
      state.allMembers.splice(index, 1)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
