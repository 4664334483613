<template>
  <div>
    <v-snackbar
      v-model="active"
      :timeout="-1"
      light
    >
      <v-row align="center">
        <v-col class="grow text-caption">
          <div class="text-caption">
            We're always looking to improve your experience at aba|tools,
            as such we'd appreciate you allowing us to collect anonymous insights into how you use aba|tools.
            No information collected will ever be sold by aba|tools.
          </div>
        </v-col>
      </v-row>

      <template v-slot:action="{ attrs }">
        <v-row align="center">
          <v-col
            cols="12"
            class="text-center"
          >
            <v-btn
              x-small
              color="atBlue"
              v-bind="attrs"
              class="white--text mb-3"
              @click="accept()"
            >
              accept
            </v-btn>
            <v-btn
              x-small
              text
              color="atBlue"
              @click="optOut()"
            >
              opt out
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { bootstrap } from 'vue-gtag'

export default {
  data () {
    return {
      active: false
    }
  },
  created () {
    if (!this.checkStatus()) {
      this.active = true
    }
  },
  methods: {
    checkStatus () {
      if (localStorage.optOutInquery) {
        const result = Boolean(JSON.parse(localStorage.optOutInquery))
        const optOut = Boolean(JSON.parse(localStorage.optOut))

        if (!optOut) {
          bootstrap().then(gtag => {
            // all done!
          })
        }

        return result
      } else {
        return false
      }
    },
    accept () {
      bootstrap().then(gtag => {
        localStorage.optOutInquery = true
        localStorage.optOut = false
        this.active = false
        // all done!
      })
    },
    optOut () {
      localStorage.optOutInquery = true
      localStorage.optOut = true
      this.active = false
    }
  }
}
</script>
