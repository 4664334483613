<template>
  <v-app>
    <v-app-bar
      color="atBlue"
      app
      dense
      dark
    >
      <router-link
        :to="{name: 'home'}"
        class="text-decoration-none white--text"
      >
        <v-toolbar-title>aba<span class="atYellow--text">|</span>tools</v-toolbar-title>
      </router-link>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <!-- <v-overlay
          :value="globalState !== 3"
        >
          <v-progress-circular
            indeterminate
            color="atBlue"
            size="64"
          />
        </v-overlay> -->
        <router-view />
      </v-container>
    </v-main>
    <v-btn
      v-show="btnToTop"
      v-scroll="onScroll"
      fab
      elevation="4"
      fixed
      bottom
      right
      small
      color="atYellow"
      class="atBlue--text"
      @click="toTop"
    >
      <v-icon large>
        mdi-chevron-up
      </v-icon>
    </v-btn>
    <v-footer
      app
      color="grey darken-3"
    >
      <v-row
        no-gutters
        justify="center"
        align="center"
      >
        <div class="text-caption text-uppercase grey--text px-2">
          &copy; 2018-{{ new Date().getFullYear() }} ABA Tools, LLC
        </div>
        <div
          v-for="(link, i) in footerLinks"
          :key="'l_'+i"
        >
          <span
            v-if="i > 0"
            class="grey--text"
          >|</span>
          <router-link
            :to="link.link"
            class="text-caption footer-link text-uppercase text-decoration-none px-2"
          >
            {{ link.title }}
          </router-link>
        </div>
        <span
          class="grey--text"
        >|</span>
        <div
          v-for="(link, i) in footerHrefs"
          :key="'h_'+i"
        >
          <span
            v-if="i > 0"
            class="grey--text"
          >|</span>
          <a
            :href="link.link"
            target="_blank"
            class="text-caption footer-link text-uppercase text-decoration-none px-2"
          >
            {{ link.title }}
          </a>
        </div>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DefaultLayout',
  data () {
    return {
      btnToTop: false,
      footerLinks: [
        {
          title: 'Terms of Service & Privacy Policy',
          link: '/legal'
        }
      ],
      footerHrefs: [
        {
          title: 'Contact Us',
          link: 'https://www.abatools.com/contact-aba-tools/'
        }
      ],
      footerSocial: [
        {
          icon: 'mdi-facebook',
          href: 'https://www.facebook.com/ABA-Tools-108266740780170/'
        },
        {
          icon: 'mdi-twitter',
          href: 'https://twitter.com/AbaTools'
        },
        {
          icon: 'mdi-linkedin',
          href: 'https://www.linkedin.com/company/aba-tools/'
        },
        {
          icon: 'mdi-youtube',
          href: 'https://www.youtube.com/channel/UCdyw3m4XoY5CrP75wlAgiyA/'
        },
        {
          icon: 'mdi-discord',
          href: 'https://discord.gg/muTYmFb'
        }
      ]
    }
  },
  computed: { ...mapGetters('user', ['globalState']) },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') { return }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.btnToTop = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>
.footer-link {
  /* color: #9E9E9E !important; */
  color: #ffcf00 !important;
}

.footer-link:hover {
  /* color: #ffcf00 !important; */
  text-decoration: underline !important;
}
</style>
