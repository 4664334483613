import { isLoggedIn } from '@/shared/utils/auth'
import { getCurrentLearner, setColors, setDictionary, setImages } from '@/shared/utils/learner'
import { getOrganizationId } from '../../shared/utils/organization'
import GENUM from '@/shared/enums/enums'
import LENUM from '@/shared/enums/LocalLoad'

const state = {
  apiState: GENUM.INIT,
  apiLocalState: LENUM.INIT,
  apiWordState: LENUM.INIT,
  details: {},
  customTargets: {},
  visibleWordSets: {},
  customSkills: [],
  item: {},
  feature: {},
  function: {},
  class: {}
}
const getters = {
}
const actions = {
  loadLearner ({ commit, dispatch }) {
    // commit('apiLocalState', LENUM.LOADING)
    dispatch('learnerDetails')
    dispatch('loadVisibleWordSets')
    dispatch('loadLearnerCustomSkills')
    // dispatch('loadCustomTargets')
    // commit('apiLocalState', LENUM.LOADED)
  },
  async learnerDetails ({ commit, rootState }) {
    // console.log('loading learner')
    if (isLoggedIn() && getCurrentLearner() !== '') {
      // console.log('user is auth')
      const learnerId = getCurrentLearner()
      try {
        commit('apiLocalState', LENUM.LOADING)
        // eslint-disable-next-line no-undef
        const learner = (await axios.get(`/api/v1/organization/learners/${learnerId}`)).data

        const target = learner.target
        const distract = learner.distract

        setColors(target, distract)
        commit('setLearnerDetails', learner)
        commit('apiLocalState', LENUM.LOADED)
      } catch (error) {
        commit('apiLocalState', LENUM.ERROR)
      }
    }
  },
  async loadCustomTargets ({ commit }) {
    if (isLoggedIn() && getCurrentLearner() !== '') {
      const learnerId = getCurrentLearner()
      try {
        commit('apiLocalState', LENUM.LOADING)
        // eslint-disable-next-line no-undef
        const customTargets = (await axios.get(`/api/v1/learner/custom-targets/${learnerId}`)).data
        commit('setCustomTargets', customTargets)
        commit('apiLocalState', LENUM.LOADED)
      } catch (error) {
        commit('apiLocalState', LENUM.ERROR)
      }
    }
  },
  async loadVisibleWordSets ({ commit }) {
    if (isLoggedIn() && getCurrentLearner() !== '') {
      const learnerId = getCurrentLearner()
      const organizationId = getOrganizationId()
      try {
        commit('apiWordState', LENUM.LOADING)
        // eslint-disable-next-line no-undef
        const visibleTargets = (await axios.get(`/api/v1/organization/${organizationId}/learner/${learnerId}/tools/vm/visible-word-sets`)).data
        commit('setVisibleWordSets', visibleTargets)
        // eslint-disable-next-line no-undef
        // const visibleItems = (await axios.get(`/api/v1/organization/${organizationId}/learner/${learnerId}/tools/vm/get-list/item`)).data
        // commit('setItems', visibleItems)
        // eslint-disable-next-line no-undef
        // const visibleFeatures = (await axios.get(`/api/v1/organization/${organizationId}/learner/${learnerId}/tools/vm/get-list/feature`)).data
        // commit('setFeatures', visibleFeatures)
        // eslint-disable-next-line no-undef
        // const visibleFunctions = (await axios.get(`/api/v1/organization/${organizationId}/learner/${learnerId}/tools/vm/get-list/function`)).data
        // commit('setFunctions', visibleFunctions)
        // eslint-disable-next-line no-undef
        // const visibleClasses = (await axios.get(`/api/v1/organization/${organizationId}/learner/${learnerId}/tools/vm/get-list/class`)).data
        // commit('setClasses', visibleClasses)
        commit('apiWordState', LENUM.LOADED)
      } catch (error) {
        commit('apiWordState', LENUM.ERROR)
      }
    }
  },
  async loadLearnerCustomSkills ({ commit }) {
    if (isLoggedIn() && getCurrentLearner() !== '') {
      // const learnerId = getCurrentLearner()
      // const organizationId = getOrganizationId()
      try {
        console.log('collecting learners skills')
        commit('apiLocalState', LENUM.LOADING)
        // eslint-disable-next-line no-undef
        const learnerSkills = (await axios.get('/api/v1/learner/skills')).data
        commit('setLearnerCustomSkills', learnerSkills)
      } catch (error) {
        console.log('Cannot collect skills for this learner.')
        commit('apiLocalState', LENUM.ERROR)
      }
    }
  },
  async loadVerbalMatrixData ({ commit }) {
    if (isLoggedIn() && getCurrentLearner() !== '') {
      const learnerId = getCurrentLearner()
      const organizationId = getOrganizationId()
      try {
        console.log('trying to put dictionary in local storage')
        commit('apiWordState', LENUM.LOADING)
        // eslint-disable-next-line no-undef
        const visibleTargets = (await axios.get(`/api/v1/organization/${organizationId}/learner/${learnerId}/tools/vm/visible-word-sets`)).data
        // commit('setVisibleWordSets', visibleTargets)
        setDictionary(visibleTargets)
        // eslint-disable-next-line no-undef
        const visibleImages = (await axios.get(`/api/v1/organization/${organizationId}/learner/${learnerId}/tools/vm/visible-image-set`)).data
        setImages(visibleImages)
        commit('apiWordState', LENUM.LOADED)
      } catch (error) {
        console.log('Cannot collect Verbal Matrix data for this learner.')
        commit('apiWordState', LENUM.ERROR)
      }
    }
  }
}
const mutations = {
  apiLocalState (state, payload) {
    state.apiLocalState = payload
  },
  apiWordState (state, payload) {
    state.apiWordState = payload
  },
  setLearnerDetails (state, payload) {
    // console.log('inside set learner details')
    // console.log(payload)
    state.details = payload
  },
  setCustomTargets (state, payload) {
    // console.log('setting custom targets')
    // console.log(payload)
    state.customTargets = payload
  },
  setVisibleWordSets (state, payload) {
    state.visibleWordSets = payload
  },
  setLearnerCustomSkills (state, payload) { state.customSkills = payload },
  setItems (state, payload) { state.item = payload },
  setFeatures (state, payload) { state.feature = payload },
  setFunctions (state, payload) { state.function = payload },
  setClasses (state, payload) { state.class = payload }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
