<template>
  <v-app>
    <v-app-bar
      color="atBlue"
      app
      dense
    >
      <v-toolbar-title>
        <router-link
          :to="{name: 'home'}"
          class="white--text text-decoration-none"
        >
          aba<span class="atYellow--text">|</span>tools
        </router-link>
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <v-main fill-height>
      <auto-logout />
      <!-- <v-container fluid> -->
      <router-view />
      <!-- </v-container> -->
    </v-main>
    <back-to-top />
  </v-app>
</template>

<script>
import BackToTop from '@/components/layout/BackToTop'
import AutoLogout from '@/components/auth/AutoLogout'

export default {
  name: 'ReceptiveIdentificationLayout',
  components: {
    AutoLogout,
    BackToTop
  },
  data () {
    return {
      btnToTop: false
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
