import { isLoggedIn } from '@/shared/utils/auth'
import LENUM from '@/shared/enums/LocalLoad'
import axios from 'axios'

const state = {
  apiState: LENUM.INIT,
  learner: {},
  allLearners: [],
  userLearners: [],
  apiErrors: null
}

const getters = {
  apiState: state => state.apiState,
  apiErrors: state => state.apiErrors,
  allLearners: state => state.allLearners,
  currentLearner: state => state.learner,
  testLearner: (state) => (id) => { return state.allLearners.filter((learner) => learner.id === id)[0] },
  userLearners: state => state.allLearners.filter((learner) => state.userLearners.includes(learner.id))
}

const actions = {
  async fetchLearners ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        commit('setApiErrors', null)
        const response = await axios.get('/api/v1/organization/learners')
        commit('setLearners', response.data)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        const errors = error.response && error.response.data.errors
        commit('setApiErrors', errors)
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async setUserLearners ({ commit, dispatch, state }, data) {
    if (isLoggedIn()) {
      if (state.allLearners.length === 0) {
        await dispatch('fetchLearners')
      }
      commit('setUserLearners', data)
    }
  },
  async setCurrentLearner ({ commit, dispatch, getters }, id) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        if (getters.allLearners.length === 0) {
          await dispatch('fetchLearners')
        }
        commit('setCurrentLearner', id)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async addLearner ({ commit, dispatch }, learner) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        commit('setApiErrors', null)
        const response = await axios.post('/api/v1/organization/learners', learner)
        const data = {
          response: response.data,
          learner: {
            distract: '#FF0000FF',
            id: response.data.learner_id,
            name: learner.name,
            target: '#00AA00FF'
          }
        }
        commit('addLearner', data)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: `Success: ${learner.name} added!`,
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        console.log(error)
        const errors = error.response && error.response.data.errors
        commit('setApiErrors', errors)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to add learner.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async delLearner ({ commit, dispatch }, learner) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        commit('setApiErrors', null)
        const response = await axios.post('/api/v1/learners/remove', learner)
        const data = {
          response: response.data,
          learner: learner
        }
        commit('delLearners', data)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: `Success: ${learner.name} removed.`,
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        const errors = error.response && error.response.data.errors
        commit('setApiErrors', errors)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Error: Unable to remove learner.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async updateLearner ({ commit, dispatch }, updatedLearner) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        commit('setApiErrors', null)
        await axios.patch('/api/v1/organization/learners/update', updatedLearner)
        commit('updateLearner', updatedLearner)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Learner has been updated.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        const errors = error.response && error.response.data.errors
        commit('setApiErrors', errors)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Error: Unable to update learner.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  addLearnerToUserLearners ({ commit }, id) {
    if (isLoggedIn()) {
      commit('addLearnerToUserLearners', id)
    }
  },
  removeLearnerToUserLearners ({ commit }, id) {
    if (isLoggedIn()) {
      commit('removeLearnerToUserLearners', id)
    }
  }
}

const mutations = {
  setApiState (state, status) {
    state.apiState = status
  },
  setApiErrors (state, errors) {
    state.apiErrors = errors
  },
  setUserLearners (state, data) {
    // const result = state.allLearners.filter((learner) => data.includes(learner.id))
    state.userLearners = data
  },
  setCurrentLearner (state, id) {
    const learner = state.allLearners.filter((learner) => learner.id === id)[0]
    state.learner = learner
  },
  setLearners (state, learners) {
    state.allLearners = learners
  },
  addLearner (state, data) {
    state.allLearners.push(data.learner)
  },
  delLearners (state, data) {
    const updatedLearners = state.allLearners.filter((learner) => learner.id !== data.learner.learner_id)
    state.allLearners = updatedLearners
  },
  updateLearner (state, updatedLearner) {
    const index = state.allLearners.findIndex((learner) => learner.id === updatedLearner.id)
    if (index !== -1) {
      const learner = state.allLearners.filter((learner) => learner.id === updatedLearner.id)[0]
      learner.name = updatedLearner.name
      learner.target = updatedLearner.target
      learner.distract = updatedLearner.distract
      state.allLearners.splice(index, 1, learner)
    }
  },
  addLearnerToUserLearners (state, id) {
    const index = state.userLearners.findIndex((ele) => ele === id)
    if (index === -1) {
      state.userLearners.push(id)
    }
  },
  removeLearnerToUserLearners (state, id) {
    const index = state.userLearners.findIndex((ele) => ele === id)
    console.log(index)
    if (index !== -1) {
      state.userLearners.splice(index, 1)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
