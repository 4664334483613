export function currentTeam (uid) {
  localStorage.setItem('CTID', uid)
}

export function getCurrentTeam () {
  return localStorage.getItem('CTID')
}

export function clearCurrentTeam () {
  localStorage.setItem('CTID', '')
}
