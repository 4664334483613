<template>
  <v-snackbar
    v-model="warningZone"
    top
    timeout="-1"
    light
    class="ma-0 pa-0"
  >
    <v-row align="center">
      <v-col class="shrink">
        <v-icon
          large
          color="warning"
        >
          mdi-alert-circle-outline
        </v-icon>
      </v-col>
      <v-col class="grow text-sm-h6">
        Are you still there? Would you like to continue your session?
      </v-col>
    </v-row>
    <template v-slot:action="{ attrs }">
      <v-row align="center">
        <v-col
          cols="12"
          class="text-center"
        >
          <v-btn
            outlined
            block
            color="success darken-1"
            v-bind="attrs"
            @click="warningMessageClear"
          >
            continue
          </v-btn>
          <v-btn
            text
            color="red darken-2"
            block
            class="mt-1"
            @click="logoutUser"
          >
            logout ({{ timerCount }})
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'AutoLogout',
  data () {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      logoutTimer: null,
      timerCount: 0,
      warningTimer: null,
      warningZone: false
    }
  },
  created () {
    window.setInterval(this.autoIncrementTimer, 1000)
  },
  mounted () {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimer)
    })
    this.setTimer()
  },
  destroyed () {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimer)
    })
    this.destroyTimers()
  },
  methods: {
    now () {
      var today = new Date()
      var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      return time
    },
    autoIncrementTimer () {
      if (this.timerCount <= 0) {
        return
      }
      this.timerCount -= 1
    },
    destroyTimers () {
      clearTimeout(this.warningTimer)
      clearTimeout(this.logoutTimer)
    },
    logoutUser () {
      this.destroyTimers()
      this.$store.dispatch('user/logout')
      this.$router.push({ name: 'login' })
      console.log('you were logged out ' + this.now())
    },
    resetTimer () {
      clearTimeout(this.warningTimer)
      this.setTimer()
    },
    resetTimerLogout () {
      clearTimeout(this.logoutTimer)
    },
    setTimer () {
      // hour * min * sec * msec
      this.warningTimer = setTimeout(this.warningMessage, 90 * 60 * 1000)
      // this.warningTimer = setTimeout(this.warningMessage, 1000)
    },
    setTimerLogout () {
      this.logoutTimer = setTimeout(this.logoutUser, 121 * 1000)
      // this.logoutTimer = setTimeout(this.logoutUser, 1000)
    },
    warningMessage () {
      console.log('you are about to be logged off ' + this.now())
      this.timerCount = 120
      this.setTimerLogout()
      this.warningZone = true
    },
    warningMessageClear () {
      this.warningZone = false
      this.timerCount = 0
      this.resetTimer()
      this.resetTimerLogout()
    }
  }
}
</script>

<style>

</style>
