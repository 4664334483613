import Vue from 'vue'
import Vuetify from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  theme: {
    themes: {
      dark: {
        atBlue: '#003390',
        atBlueT: '#ffffff',
        atYellow: '#ffcf00'
      },
      light: {
        primary: '#003390',
        atBlue: '#0f4c81',
        atBlueTest: '#0f4c81',
        atBlueOriginal: '#003390',
        atYellow: '#ffcf00'
      }
    }
  }
}

export default new Vuetify(opts)
