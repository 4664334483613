import { isLoggedIn, logOut, setSub, setQuantity, mapPermissions } from '@/shared/utils/auth'
import { createInitials, localUser, localOrganization } from '@/shared/utils/user'
import { setOrganizationId } from '@/shared/utils/organization'

import GENUM from '@/shared/enums/enums'
import LENUM from '@/shared/enums/LocalLoad'
import axios from 'axios'

const state = {
  apiState: GENUM.INIT,
  apiErrors: null,
  apiLocalState: LENUM.INIT,
  isLoggedIn: false,
  details: {},
  permissions: {},
  organization: {}
}
const getters = {
  globalState: state => state.apiState,
  isAuth: state => !!state.isLoggedIn,
  userDetails: state => state.details,
  userPermissions: state => state.permissions,
  userOrganization: state => state.organization
}
const actions = {
  async loadEportalUser ({ commit, dispatch }) {
    if (isLoggedIn()) {
      try {
        const hasPermission = (await axios.get('/api/v1/check-permission')).data
        if (hasPermission) {
          commit('setApiState', GENUM.LOADING)
          const response = (await axios.get('/api/v1/user-data')).data
          const user = {
            name: response.user.u_name,
            title: response.user.u_title,
            email: response.user.u_email,
            role: response.user.u_role,
            since: response.user.u_since
          }
          localStorage.setItem('epat', response.user.role)
          const organization = {
            id: response.user.o_uuid,
            name: response.user.o_name,
            subscription: response.subscription.plan,
            status: response.subscription.status,
            quantity: response.subscription.quantity,
            since: response.user.o_since
          }
          localUser(user)
          user.id = response.user.u_uuid
          commit('setUser', user)
          commit('setUserOrganization', organization)
          localOrganization(organization)

          const permissions = (await axios.get('/api/v1/user/permissions')).data
          commit('setUserPermissions', mapPermissions(permissions))

          commit('setLoggedIn', true)
          commit('setApiState', GENUM.LOADED)
        } else {
          commit('setApiState', GENUM.ERROR)
          // dispatch('logout')
        }
      } catch (error) {
        // console.log(error)
        const errors = error.response && error.response.data.errors
        commit('setApiErrors', errors)
        commit('setApiState', GENUM.ERROR)
        // dispatch('logout')
      }
    }
  },
  async loadUser ({ commit, dispatch }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', GENUM.LOADING)
        const response = (await axios.get('/api/v1/user-data')).data
        const user = {
          name: response.user.u_name,
          title: response.user.u_title,
          email: response.user.u_email,
          role: response.user.u_role,
          since: response.user.u_since
        }
        const organization = {
          id: response.user.o_uuid,
          name: response.user.o_name,
          subscription: response.subscription.plan,
          ends: response.subscription.ends_at,
          status: response.subscription.status,
          quantity: response.subscription.quantity,
          since: response.user.o_since
        }
        const learners = response.learners
        localUser(user)
        user.id = response.user.u_uuid
        // dispatch('teams/setUserTeams', user.id, { root: true })
        dispatch('learners/setUserLearners', learners, { root: true })
        commit('setUser', user)
        localOrganization(organization)
        organization.pp = response.user.o_paypal
        commit('setUserOrganization', organization)

        const permissions = (await axios.get('/api/v1/user/permissions')).data
        commit('setUserPermissions', mapPermissions(permissions))

        commit('setLoggedIn', true)
        commit('setApiState', GENUM.LOADED)
      } catch (error) {
        // console.log(error)
        commit('setApiState', GENUM.ERROR)
        // dispatch('logout')
      }
    }
  },
  async setUser ({ commit, dispatch, getters }) {
    if (isLoggedIn()) {
      commit('setApiState', GENUM.LOADING)
      // eslint-disable-next-line no-undef
      if (jQuery.isEmptyObject(getters.userDetails) || jQuery.isEmptyObject(getters.userOrganization) || jQuery.isEmptyObject(getters.userPermissions)) {
        await dispatch('loadUser')
      }
      commit('setApiState', GENUM.LOADED)
    }
  },
  async setEportalUser ({ commit, dispatch, getters }) {
    if (isLoggedIn()) {
      commit('setApiState', GENUM.LOADING)
      // eslint-disable-next-line no-undef
      if (jQuery.isEmptyObject(getters.userDetails) || jQuery.isEmptyObject(getters.userOrganization) || jQuery.isEmptyObject(getters.userPermissions)) {
        await dispatch('loadEportalUser')
      }
      commit('setApiState', GENUM.LOADED)
    }
  },
  changeUserPermission ({ commit }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', GENUM.LOADING)
        commit('changeUserPermission', mapPermissions(data.permissions))
        commit('setApiState', GENUM.LOADED)
      } catch (error) {
        commit('setApiState', GENUM.ERROR)
      }
    }
  },
  async updateUser ({ commit, dispatch }) {
    if (isLoggedIn()) {
      try {
        commit('apiLocalState', LENUM.LOADING)
        const user = (await axios.get('/user')).data
        const organization = (await axios.get('/api/v1/user-data')).data
        commit('setUser', user)
        commit('setUserInitials', createInitials(user.name, 'userInitials'))
        commit('setUserOrganization', organization)
        setSub(organization.o_subscription)
        setQuantity(organization.o_quantity)
        setOrganizationId(organization.o_uuid)
        commit('setUserOrganizationInitials', createInitials(organization.o_name, 'orgInitials'))
        commit('setLoggedIn', true)
        commit('apiLocalState', LENUM.LOADED)
      } catch (error) {
        commit('apiLocalState', LENUM.ERROR)
        dispatch('logout')
      }
    }
  },
  async editUser ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('apiLocalState', LENUM.LOADING)
        commit('setApiErrors', null)
        await axios.patch(`/api/v1/user-data/${data.id}/update`, data)
        commit('editUser', data)
        commit('apiLocalState', LENUM.LOADED)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Account details updated.',
          color: 'success darken-2'
        }, { root: true })
      } catch (error) {
        commit('apiLocalState', LENUM.ERROR)
        const errors = error.response && error.response.data.errors
        commit('setApiErrors', errors)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to change account details.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },
  async editOrganization ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('apiLocalState', LENUM.LOADING)
        commit('setApiErrors', null)
        await axios.patch(`/api/v1/org-data/${data.id}/update`, data)
        commit('editOrganization', data)
        commit('apiLocalState', LENUM.LOADED)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Organization details updated.',
          color: 'success darken-2'
        }, { root: true })
      } catch (error) {
        commit('apiLocalState', LENUM.ERROR)
        const errors = error.response && error.response.data.errors
        commit('setApiErrors', errors)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to change organization details.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },

  logout ({ commit }) {
    // CLEAR EPORTAL
    localStorage.removeItem('epat')
    // CLEAR LEARNER
    commit('learners/setLearners', [], { root: true })
    commit('learners/setUserLearners', [], { root: true })
    commit('learners/setCurrentLearner', {}, { root: true })
    // CLEAR TEAMS
    commit('teams/setTeams', [], { root: true })
    commit('teams/setAllTeamMembers', [], { root: true })
    commit('teams/setUserTeams', [], { root: true })
    commit('teams/setDataLearnersTeams', [], { root: true })
    commit('teams/setLearnerTeams', [], { root: true })
    commit('teams/setCurrentTeam', {}, { root: true })
    commit('teams/setCurrentTeamMembers', [], { root: true })
    commit('teams/setCurrentTeamLearners', [], { root: true })
    // CLEAR SKILLS
    commit('skills/setSkills', [], { root: true })
    commit('skills/setAssessmentSkills', [], { root: true })
    commit('skills/setAllLearnerSkills', [], { root: true })
    commit('skills/setAllLearnerAssessmentSkills', [], { root: true })
    commit('skills/setLearnerSkills', {}, { root: true })
    commit('skills/setLearnerAssessmentSkills', {}, { root: true })
    commit('skills/setCurrentSkill', {}, { root: true })
    commit('skills/setCurrentAssessmentSkill', {}, { root: true })
    // CLEAR USER
    commit('setUser', {})
    commit('setUserPermissions', {})
    commit('setUserOrganization', {})
    commit('setLoggedIn', false)
    // LOGOUT
    axios.post('/logout')
    logOut()
  }
}
const mutations = {
  setApiState (state, payload) {
    state.apiState = payload
  },
  setApiErrors (state, payload) {
    state.apiErrors = payload
  },
  apiLocalState (state, payload) {
    state.apiLocalState = payload
  },
  setUser (state, payload) {
    state.details = payload
  },
  editUser (state, data) {
    if (data.name) {
      state.details.name = data.name
    }
    if (data.email) {
      state.details.email = data.email
    }
  },
  editOrganization (state, data) {
    state.organization.name = data.name
  },
  setUserOrganization (state, payload) {
    state.organization = payload
  },
  setUserPermissions (state, payload) {
    state.permissions = payload
  },
  changeUserPermission (state, data) {
    state.permissions = data
  },
  // setUserOrganizationInitials (state, payload) {
  //   state.orgInitials = payload
  // },
  // setUserInitials (state, payload) {
  //   state.initials = payload
  // },
  setLoggedIn (state, payload) {
    state.isLoggedIn = payload
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
