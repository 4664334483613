<template>
  <v-menu
    v-model="acctMenu"
    offset-y
    min-width="200"
    max-width="200"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <v-avatar
          color="white"
          size="28"
        >
          <span class="atBlue--text text-subtitle-2 font-weight-bold">{{ avatar }}</span>
        </v-avatar>
        <v-icon>
          mdi-menu-down
        </v-icon>
      </div>
    </template>
    <v-list>
      <v-list-item
        :two-line="userOrganization.subscription !== 3"
        :three-line="userOrganization.subscription === 3"
      >
        <v-list-item-content>
          <v-list-item-title>{{ username }}</v-list-item-title>
          <v-list-item-subtitle
            v-if="userOrganization.subscription === 3"
            class=""
          >
            {{ office }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="atBlue--text text-uppercase font-weight-bold">
            <small>{{ subscription }}</small>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="atBlue" />
      <v-list-item-group color="atBlue">
        <div
          v-for="(item, index) in acctMenuLinksA"
          :key="'primary_'+index"
        >
          <v-list-item
            v-if="item.permission"
            color="atBlue"
            dense
            :to="{ name: item.route}"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
      <v-divider class="atBlue" />
      <v-list-item-group color="atBlue">
        <div
          v-for="(item, index) in acctMenuLinksB"
          :key="'secondary_'+index"
        >
          <v-list-item
            v-if="item.permission"
            color="atBlue"
            dense
            exact
            :to="{ name: item.route}"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-list-item
          dense
          exact
          @click.prevent="logout"
        >
          <v-list-item-content>
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserMenu',
  props: {
    avatar: { type: String, default: null },
    office: { type: String, default: null },
    subscription: { type: String, default: null },
    username: { type: String, default: null }
  },
  data () {
    return {
      acctMenu: false
    }
  },
  computed: {
    ...mapGetters('user', [
      'userDetails',
      'userOrganization',
      'userPermissions'
    ]),
    acctMenuLinksA () {
      const result = [
        {
          route: 'user-dashboard',
          title: 'Dashboard',
          permission: true
        },
        {
          route: 'user-learners',
          title: 'Learners',
          permission: this.userPermissions ? this.userPermissions.visible_learners === 1 : false
        },
        {
          route: 'user-team',
          title: 'Teams',
          permission: this.userPermissions ? this.userPermissions.visible_teams === 1 : false
        },
        {
          route: 'resources-skills',
          title: 'Skills',
          permission: this.userPermissions ? this.userPermissions.visible_skills === 1 : false

        }
      ]

      return result
    },
    acctMenuLinksB () {
      const result = [
        {
          route: 'user-account-overview',
          title: 'Account',
          permission: true
        },
        {
          route: 'user-manage-organization',
          title: 'Organization',
          permission: this.userPermissions ? this.userPermissions.visible_organization === 1 : false
        },
        {
          route: 'tutorials',
          title: 'Tutorials',
          permission: true
        }
      ]

      return result
    }
  },
  methods: {
    async logout () {
      try {
        this.$store.dispatch('user/logout')
        this.$router.push({ name: 'login' })
      } catch (error) {
        this.$store.dispatch('user/logout')
        this.$router.push({ name: 'login' })
      }
    }
  }
}
</script>

<style>

</style>
