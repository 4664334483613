import VueRouter from 'vue-router'
// import NProgress from 'nprogress'
import store from '../store'
// import '../../../node_modules/nprogress/nprogress.css'

const routes = [
  {
    path: '/',
    name: 'eportal-home',
    component: () => import(/* webpackChunkName: "eportal-dashboard" */ '@/views/eportal/dashboard/Index.vue'),
    meta: { requiresAuth: true }
  },
  // login auth
  {
    path: '/login',
    name: 'eportal-login',
    component: () => import(/* webpackChunkName: "eportal-login" */ '@/views/eportal/auth/Login.vue'),
    meta: { layout: 'login', title: 'Login' }
  },
  {
    path: '/dashboard',
    name: 'eportal-dashboard',
    component: () => import(/* webpackChunkName: "eportal-dashboard" */ '@/views/eportal/dashboard/Index.vue'),
    meta: { requiresAuth: true, requiresUser: true, title: 'Dashboard' }
  },
  {
    path: '/statistics',
    name: 'eportal-statistics',
    component: () => import(/* webpackChunkName: "eportal-dashboard" */ '@/views/eportal/statistics/Index.vue'),
    meta: { requiresAuth: true, requiresUser: true, title: 'Statistics' }
  },
  {
    path: '/account',
    name: 'eportal-account',
    meta: { requiresAuth: true, requiresUser: true, title: 'Account' }
  },
  // forms
  {
    path: '/forms',
    component: () => import(/* webpackChunkName: "eportal-forms-index" */ '@/views/eportal/forms/Index.vue'),
    meta: { requiresAuth: true, requiresUser: true, title: 'Forms' },
    children: [
      {
        path: '',
        name: 'eportal-forms-list',
        component: () => import(/* webpackChunkName: "eportal-forms-list" */ '@/views/eportal/forms/List.vue'),
        meta: { requiresAuth: true, requiresUser: true, title: 'Forms List' }
      },
      {
        path: 'tutorial-builder',
        name: 'eportal-tutorial-builder',
        component: () =>
          import(/* webpackChunkName: "eportal-tutorial-builder" */ '@/views/eportal/forms/TutorialBuilder.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          title: 'Tutorial Builder'
        }
      },
      {
        path: 'manage-words',
        name: 'eportal-forms-words',
        component: () => import(/* webpackChunkName: "eportal-forms-words" */ '@/views/eportal/forms/Words.vue'),
        meta: { requiresAuth: true, requiresUser: true, title: 'Manage Words' }
      },
      {
        path: 'manage-word-relationships',
        name: 'eportal-forms-word-relationships',
        component: () =>
          import(
            /* webpackChunkName: "eportal-forms-word-relationships" */ '@/views/eportal/forms/WordRelationships.vue'
          ),
        meta: {
          requiresAuth: true,
          requiresUser: true,
          title: 'Manage Word Relationships'
        }
      },
      {
        path: 'push-word-sets',
        name: 'eportal-forms-push-word-sets',
        component: () =>
          import(/* webpackChunkName: "eportal-push-word-sets" */ '@/views/eportal/forms/WordSetPush.vue'),
        meta: {
          requiresAuth: true,
          requiresGoblin: true,
          title: 'Push Word Sets'
        }
      },
      {
        path: 'upload-images',
        name: 'eportal-forms-image-uploader',
        component: () =>
          import(/* webpackChunkName: "eportal-forms-uploader" */ '@/views/eportal/forms/images/uploader/Index.vue'),
        meta: {
          requiresAuth: true,
          requiresGoblin: true,
          title: 'Image Uploader'
        }
      },
      {
        path: 'manage-image-relationships',
        name: 'eportal-forms-image-relationships',
        component: () =>
          import(
            /* webpackChunkName: "eportal-forms-image-relationships" */ '@/views/eportal/forms/ImageRelationships.vue'
          ),
        meta: {
          requiresAuth: true,
          requiresUser: true,
          title: 'Manage Image Relationships'
        }
      },
      {
        path: 'skill-duplicator',
        name: 'eportal-forms-skill-duplicator',
        component: () =>
          import(/* webpackChunkName: "eportal-forms-skill-duplicator" */ '@/views/eportal/forms/SkillDuplicator.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          title: 'Skill Duplicator'
        }
      },
      {
        path: 'assessment-skills',
        component: () =>
          import(
            /* webpackChunkName: "eportal-forms-assessment-skills-container" */ '@/views/eportal/forms/AssessmentSkills/Container.vue'
          ),
        meta: {
          requiresAuth: true,
          requiresUser: true,
          title: 'Assessment Skills Overview'
        },
        children: [
          {
            path: '',
            name: 'eportal-forms-assessment-skills-list',
            component: () =>
              import(
                /* webpackChunkName: "eportal-forms-assessment-skills-list" */ '@/views/eportal/forms/AssessmentSkills/List.vue'
              ),
            meta: {
              requiresAuth: true,
              requiresUser: true,
              title: 'Assessment Skills Overview'
            }
          },
          {
            path: 'builder',
            name: 'eportal-forms-assessment-skills-builder',
            component: () =>
              import(
                /* webpackChunkName: "eportal-forms-assessment-skills-builder" */ '@/views/eportal/forms/AssessmentSkills/Builder.vue'
              ),
            meta: { title: 'Assessment Builder', requiresAuth: true, requiresAdmin: true }
          },
          {
            path: ':id/edit',
            name: 'eportal-forms-assessment-skills-edit',
            component: () =>
              import(
                /* webpackChunkName: "eportal-forms-assessment-skills-edit" */ '@/views/eportal/forms/AssessmentSkills/Edit.vue'
              ),
            meta: { title: 'Assessment Editor', requiresAuth: true, requiresAdmin: true }
          }
        ]
      },
      {
        path: 'image-tagging',
        component: () =>
          import(/* webpackChunkName: "eportal-image-tagging-index" */ '@/views/eportal/forms/ImageTagging/Index.vue'),
        meta: {
          requiresAuth: true,
          requiresUser: true,
          title: 'Image Tagging'
        },
        children: [
          {
            path: '',
            name: 'eportal-forms-image-tagging',
            component: () =>
              import(
                /* webpackChunkName: "eportal-image-tagging-list" */ '@/views/eportal/forms/ImageTagging/ImageTagging.vue'
              ),
            meta: {
              requiresAuth: true,
              requiresUser: true,
              title: 'Image Tagging'
            }
          }
        ]
      },
      {
        path: 'template-creator',
        name: 'eportal-template-creator',
        component: () =>
          import(/* webpackChunkName: "eportal-template-creator" */ '@/views/eportal/forms/Assessments.vue'),
        meta: {
          requiresAuth: true,
          requiresUser: true,
          title: 'Template Creator'
        }
      },
      {
        path: 'therapy-styles',
        name: 'eportal-therapy-styles',
        component: () =>
          import(/* webpackChunkName: "eportal-therapy-styles" */ '@/views/eportal/forms/TherapyStyles.vue'),
        meta: {
          requiresAuth: true,
          requiresUser: true,
          title: 'Add Therapy Style'
        }
      }
    ]
  },
  // reports
  {
    path: '/reports',
    component: () => import(/* webpackChunkName: "eportal-reports-index" */ '@/views/eportal/reports/Index.vue'),
    meta: { requiresAuth: true, requiresUser: true, title: 'Reports' },
    children: [
      {
        path: '',
        name: 'eportal-reports-list',
        component: () => import(/* webpackChunkName: "eportal-reports-list" */ '@/views/eportal/reports/List.vue'),
        meta: { requiresAuth: true, requiresUser: true, title: 'Reports List' }
      },
      {
        path: 'images',
        name: 'eportal-reports-images',
        component: () => import(/* webpackChunkName: "eportal-reports-images" */ '@/views/eportal/reports/Images.vue'),
        meta: { requiresAuth: true, requiresUser: true, title: 'Images Report' }
      },
      {
        path: 'image-relationships',
        name: 'eportal-reports-image-relationships',
        component: () =>
          import(
            /* webpackChunkName: "eportal-reports-image-relationships" */ '@/views/eportal/reports/ImageRelationships.vue'
          ),
        meta: {
          requiresAuth: true,
          requiresUser: true,
          title: 'Image Relationships Report'
        }
      },
      {
        path: 'mturk',
        name: 'eportal-reports-mturk',
        component: () =>
          import(/* webpackChunkName: "eportal-reports-mturk" */ '@/views/eportal/reports/ImagesMturk.vue'),
        meta: {
          requiresAuth: true,
          requiresUser: true,
          title: 'Image Relationships Report'
        }
      },
      {
        path: 'tags',
        name: 'eportal-reports-tags',
        component: () => import(/* webpackChunkName: "eportal-reports-tags" */ '@/views/eportal/reports/Tags.vue'),
        meta: { requiresAuth: true, requiresUser: true, title: 'Tags Report' }
      },
      {
        path: 'tag-relationships',
        name: 'eportal-reports-tag-relationships',
        component: () =>
          import(
            /* webpackChunkName: "eportal-reports-tag-relationships" */ '@/views/eportal/reports/TagRelationships.vue'
          ),
        meta: {
          requiresAuth: true,
          requiresUser: true,
          title: 'Tag Relationships Report'
        }
      },
      {
        path: 'words',
        name: 'eportal-reports-words',
        component: () => import(/* webpackChunkName: "eportal-reports-words" */ '@/views/eportal/reports/Words.vue'),
        meta: { requiresAuth: true, requiresUser: true, title: 'Words Report' }
      },
      {
        path: 'word-relationships',
        name: 'eportal-reports-word-relationships',
        component: () =>
          import(
            /* webpackChunkName: "eportal-reports-word-relationships" */ '@/views/eportal/reports/WordRelationships.vue'
          ),
        meta: {
          requiresAuth: true,
          requiresUser: true,
          title: 'Word Relationships Report'
        }
      },
      {
        path: 'word-relationships-slow',
        name: 'eportal-reports-word-relationships-slow',
        component: () =>
          import(
            /* webpackChunkName: "eportal-reports-word-relationships-slow" */ '@/views/eportal/reports/WordRelationshipsSlow.vue'
          ),
        meta: {
          requiresAuth: true,
          requiresUser: true,
          title: 'Word Relationships Report'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? 'e|portal - ' + to.meta.title : 'e|portal'
  next()
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.isLoggedIn === 'true') {
      store.dispatch('user/setEportalUser').then(() => next())
    } else {
      store.dispatch('currentUser/logout')
      next({ name: 'eportal-login', query: { redirect: to.fullPath } })
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresGoblin)) {
    if (localStorage.epat === 'atGoblin') {
      store.dispatch('user/setEportalUser').then(() => next())
    } else if (localStorage.epat === 'atAdmin') {
      next({ name: 'eportal-home' })
    } else if (localStorage.epat === 'atUser') {
      next({ name: 'eportal-home' })
    } else {
      store.dispatch('currentUser/logout')
      next({ name: 'eportal-login' })
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (localStorage.epat === 'atAdmin') {
      store.dispatch('user/setEportalUser').then(() => next())
    } else if (localStorage.epat === 'atGoblin') {
      store.dispatch('user/setEportalUser').then(() => next())
    } else if (localStorage.epat === 'atUser') {
      next({ name: 'eportal-home' })
    } else {
      store.dispatch('currentUser/logout')
      next({ name: 'eportal-login' })
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresUser)) {
    if (localStorage.epat === 'atUser') {
      store.dispatch('user/setEportalUser').then(() => next())
    } else if (localStorage.epat === 'atGoblin') {
      store.dispatch('user/setEportalUser').then(() => next())
    } else if (localStorage.epat === 'atAdmin') {
      store.dispatch('user/setEportalUser').then(() => next())
    } else {
      store.dispatch('currentUser/logout')
      next({ name: 'eportal-login' })
    }
  } else {
    next()
  }
})

export default router
