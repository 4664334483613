<template>
  <v-app>
    <v-app-bar
      app
      clipped-left
      color="atBlue"
      dense
      dark
    >
      <v-app-bar-nav-icon @click.stop="dashNav = !dashNav" />
      <router-link
        :to="{name: 'home'}"
        class="text-decoration-none white--text"
      >
        <v-toolbar-title>aba<span class="atYellow--text">|</span>tools</v-toolbar-title>
      </router-link>
      <v-spacer />
      <user-menu
        v-if="user.isLoggedIn"
        :avatar="user.initials"
        :office="user.organization.o_subscription === 1 ? user.organization.u_title : user.organization.o_name"
        :subscription="subscription"
        :username="user.details.name"
      />
    </v-app-bar>

    <v-navigation-drawer
      v-if="user"
      v-model="dashNav"
      app
      clipped
      fixed
    >
      <v-list>
        <v-list-item
          two-line
        >
          <v-list-item-avatar
            class="atBlue"
          >
            <span class="white--text text-h6">{{ user.organization.o_subscription === 1 ? user.initials : user.orgInitials }}</span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-capitalize">
              <!-- {{ user.organization.o_name }} -->
              {{ user.organization.o_subscription === 1 ? user.details.name : user.organization.o_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <!-- {{ user.details.name }} -->
              {{ user.organization.o_subscription === 1 ? user.organization.u_title : user.details.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="atBlue--text text-uppercase font-weight-bold">
              <small>{{ subscription }}</small>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-group color="atBlue">
          <v-list-item
            v-for="(item, i) in dashboardNavigation"
            :key="i"
            :to="{ name: item.route }"
            link
            exact
          >
            <v-list-item-action>
              <v-icon :color="item.color">
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-list>
          <v-list-item-group color="atBlue">
            <v-list-item>
              <v-list-item-action>
                <v-icon color="atBlue">
                  mdi-view-dashboard
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Dashboard
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <!-- <div class="pa-2">
          <v-btn block>
            Logout
          </v-btn>
        </div> -->
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <back-to-top />
    <big-footer />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

import BackToTop from '@/components/layout/BackToTop'
import BigFooter from '@/components/layout/BigFooter'
import UserMenu from '@/components/layout/UserMenu'

export default {
  name: 'AccountLayout',
  components: {
    BackToTop,
    BigFooter,
    UserMenu
  },
  data () {
    return {
      dashNav: false,
      dashboardNavigation: [
        {
          route: 'user-account-overview',
          icon: 'mdi-view-dashboard',
          color: 'atBlue',
          title: 'Overview'
        },
        {
          route: 'user-account-billing',
          icon: 'mdi-wallet-membership',
          color: 'atBlue',
          title: 'Billing'
        },
        {
          route: 'user-manage-organization',
          icon: 'mdi-account-supervisor',
          color: 'atBlue',
          title: 'Organization'
        },
        {
          route: 'user-account-details',
          icon: 'mdi-account',
          color: 'atBlue',
          title: 'Details'
        },
        {
          route: 'user-account-security',
          icon: 'mdi-lock',
          color: 'atBlue',
          title: 'Security'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      user: state => state.currentUser
    }),
    subscription () {
      switch (Number(localStorage.sub)) {
        case (1):
          return 'free'
        case (2):
          return 'home'
        case (3):
          return 'pro'
        default:
          return 'unauthorized'
      }
    }
  },
  mounted () {
    this.initDashNav()
  },
  methods: {
    initDashNav () {
      var dash = window.innerWidth > 1264
      this.dashNav = dash
    },
    createInitials (name) {
      const data = name.split(' ')
      let output = ''

      function howMany () {
        if (data.length > 2) {
          return 2
        } else {
          return data.length
        }
      }

      for (var i = 0; i < howMany(); i++) {
        output += data[i].substring(0, 1)
      }
      return output.toUpperCase()
    }
  }
}
</script>

<style scoped>
.v-toolbar__extension {
  padding: 0px !important;
}
</style>
