export function organizationTeamData (data) {
  localStorage.setItem('orgT', data)
}

export function organizationLearnerData (data) {
  localStorage.setItem('orgL', data)
}

export function organizationUserData (data) {
  localStorage.setItem('orgM', data)
}

export function getOrganizationId (data) {
  return localStorage.getItem('orgId')
}

export function setOrganizationId (uid) {
  localStorage.setItem('orgId', uid)
}

export function clearOrganizationId () {
  localStorage.setItem('orgId', '')
}
