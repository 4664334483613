import LENUM from '@/shared/enums/LocalLoad'
import { isLoggedIn } from '../../shared/utils/auth'
import axios from 'axios'

const state = {
  apiState: LENUM.INIT,
  node: {},
  skillTitle: '',
  skillTemplates: []
}

const getters = {
  skillTitle: state => state.skillTitle,
  skillTemplates: state => state.skillTemplates
}

const actions = {
  async fetchSkillTemplates ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const templates = (await axios.get('/api/v1/skills/objectives')).data
        commit('setSkillTemplates', templates)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  clearSkillBuilder ({ commit }) {
    if (isLoggedIn()) {
      commit('clearSkillBuilder')
    }
  },
  setSkillTitle ({ commit }, data) {
    if (isLoggedIn()) {
      commit('setSkillTitle', data)
    }
  }
}

const mutations = {
  setApiState (state, status) {
    state.apiState = status
  },
  setSkillTemplates (state, templates) {
    state.skillTemplates = templates
  },
  clearSkillBuilder (state) {
    state.node = {}
    state.skillTitle = ''
  },
  setSkillTitle (state, title) {
    state.skillTitle = title
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
