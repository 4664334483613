import LENUM from '@/shared/enums/LocalLoad'
import { isLoggedIn } from '../../shared/utils/auth'
import axios from 'axios'

const state = {
  apiState: LENUM.INIT,
  data: []
}

const getters = {
  assessments: state => [
    ...new Set(
      state.data.map(element => {
        return element.assessment
      })
    )
  ],
  skillAreas: state => [
    ...new Set(
      state.data.map(element => {
        return element.skill_area
      })
    )
  ],
  skillAreaByAssessment: (state) => (assessment) => [
    ...new Set(
      state.data.filter(element => element.assessment === assessment)
        .map(element => {
          return element.skill_area
        })
    )
  ],
  targets: state => [
    ...new Set(
      state.data.map(element => {
        return element.target
      })
    )
  ],
  targetsBySkillArea: (state) => (skillArea) => [
    ...new Set(
      state.data
        .filter(element => element.skill_area === skillArea)
        .map(element => {
          return element.target
        })
    )
  ],
  targetsByAssessmentBySkillArea: (state) => (assessment, skillArea) => [
    ...new Set(
      state.data.filter(element => element.assessment === assessment)
        .filter(element => element.skill_area === skillArea)
        .map(element => {
          return element.target
        })
    )
  ],
  therapyStyleData: state => state.data
}

const mutations = {
  setApiState (state, status) {
    state.apiState = status
  },
  setAllTherapyStyledata (state, payload) {
    state.data = payload
  },
  setAllTherapyStyleFormData (state, payload) {
    state.formData = payload
  },
  addTherapyStyle (state, payload) {
    state.data.push(payload)
  },
  editTherapyStyle (state, payload) {
    const index = state.data.findIndex(style => style.id === payload.id)
    const style = {
      id: payload.id,
      assessment: payload.assessment,
      skill_area: payload.skill_area,
      target: payload.target
    }
    if (index !== -1) {
      state.data.splice(index, 1, style)
    }
  },
  removeTherapyStyle (state, payload) {
    const index = state.data.findIndex(style => style.id === payload.id)
    state.data.splice(index, 1)
  }
}

const actions = {
  async fetchTherapyStyles ({ commit }) {
    if (isLoggedIn()) {
      commit('setApiState', LENUM.LOADING)
      const data = (await axios.get('/api/v1/eportal/therapy-styles')).data
      commit('setAllTherapyStyledata', data)
      commit('setApiState', LENUM.LOADED)
    }
  },
  async saveTherapyStyle ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = (await axios.post('/api/v1/eportal/therapy-styles', data)).data
        commit('addTherapyStyle', response)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-information',
            text: 'Success: New Therapy Style added.',
            color: 'success darken-2'
          },
          { root: true }
        )
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-alert',
            text: 'Error: Something went wrong.',
            color: 'error darken-2'
          },
          { root: true }
        )
      }
    }
  },
  async updateTherapyStyle ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = (await axios.patch(`/api/v1/eportal/therapy-styles/${data.id}`, data)).data
        commit('editTherapyStyle', response)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-information',
            text: 'Success: Therapy Style edited.',
            color: 'success darken-2'
          },
          { root: true }
        )
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-alert',
            text: 'Error: Something went wrong.',
            color: 'error darken-2'
          },
          { root: true }
        )
      }
    }
  },
  async deleteTherapyStyle ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.post(`/api/v1/eportal/therapy-styles/${data.id}`)
        commit('removeTherapyStyle', data)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-information',
            text: 'Success: Therapy Style removed.',
            color: 'success darken-2'
          },
          { root: true }
        )
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-alert',
            text: 'Error: Something went wrong.',
            color: 'error darken-2'
          },
          { root: true }
        )
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
