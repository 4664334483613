<template>
  <v-menu
    v-model="acctMenu"
    offset-y
    max-width="200"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <v-avatar
          color="white"
          size="28"
        >
          <span class="atBlue--text text-subtitle-2 font-weight-bold">{{ avatar }}</span>
        </v-avatar>
        <v-icon>
          mdi-menu-down
        </v-icon>
      </div>
    </template>
    <v-list>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>{{ username }}</v-list-item-title>
          <v-list-item-subtitle class="">
            {{ office }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="atBlue" />
      <v-list-item-group color="atBlue">
        <v-list-item
          v-for="(item, index) in acctMenuLinksA"
          :key="index"
          color="atBlue"
          dense
          exact
          :to="{ name: item.route}"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-divider class="atBlue" />
      <v-list-item-group color="atBlue">
        <v-list-item
          v-for="(item, index) in acctMenuLinksB"
          :key="index"
          color="atBlue"
          dense
          exact
          :to="{ name: item.route}"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          dense
          exact
          @click.prevent="logout"
        >
          <v-list-item-content>
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'UserMenu',
  props: {
    avatar: { type: String, default: null },
    office: { type: String, default: null },
    subscription: { type: String, default: null },
    username: { type: String, default: null }
  },
  data () {
    return {
      acctMenu: false,
      acctMenuLinksA: [
        {
          route: 'eportal-dashboard',
          title: 'Dashboard'
        },
        {
          route: 'eportal-statistics',
          title: 'Statistics'
        },
        {
          route: 'eportal-forms-list',
          title: 'Forms'
        },
        {
          route: 'eportal-reports-list',
          title: 'Reports'
        }

      ],
      acctMenuLinksB: [
        {
          route: 'eportal-account',
          title: 'Account'
        }
      ]
    }
  },
  methods: {
    async logout () {
      try {
        this.$store.dispatch('user/logout')
        this.$router.push({ name: 'eportal-login' })
      } catch (error) {
        this.$store.dispatch('user/logout')
        this.$router.push({ name: 'eportal-login' })
      }
    }
  }
}
</script>

<style>

</style>
