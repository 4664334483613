import LENUM from '@/shared/enums/LocalLoad'
import { isLoggedIn } from '../../shared/utils/auth'
import axios from 'axios'

const state = {
  apiState: LENUM.INIT,
  tags: [],
  tagsDetailed: [],
  wordSets: []
}

const getters = {
  allTags: state => state.tags,
  allTagsDetailed: state => state.tagsDetailed
}

const actions = {
  async fetchTags ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const tags = (await axios.get('/api/v1/eportal/tags')).data
        commit('setTags', tags)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async fetchTagsWithDetails ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const tags = (await axios.get('/api/v1/eportal/tags-details')).data
        commit('setTagsDetailed', tags)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async template ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const wordSets = (await axios.get('/api/v1/word_sets')).data
        commit('setWordSets', wordSets)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async createNewTag ({ commit, dispatch }, tag) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = (await axios.post('/api/v1/eportal/tags', tag)).data
        commit('addNewTag', response)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-information',
          text: `Success: New tag (${tag.tag}) created.`,
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Something went wrong creating this tag.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },
  async updateTag ({ commit, dispatch }, tag) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.patch(`/api/v1/eportal/tags/${tag.id}`, tag)
        commit('updateTag', tag)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-information',
          text: `Success: Tag (${tag.tag}) has been changed.`,
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Something went wrong updating this tag.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },
  async destroyTag ({ commit, dispatch }, tag) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.post(`/api/v1/eportal/tags/${tag.id}`)
        commit('deleteTag', tag)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-information',
          text: `Success: Tag (${tag.tag}) has been deleted.`,
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Something went wrong deleting this tag.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  }
}

const mutations = {
  setApiState (state, status) {
    state.apiState = status
  },
  setTags (state, tags) {
    state.tags = tags
  },
  setTagsDetailed (state, tags) {
    state.tagsDetailed = tags
  },
  addNewTag (state, tag) {
    state.tags.push({ id: tag.id, tag: tag.tag })
  },
  updateTag (state, payload) {
    const indexOne = state.tags.findIndex(tag => tag.id === payload.id)
    if (indexOne !== -1) {
      state.tags.splice(indexOne, 1, payload)
    }
    const indexTwo = state.tagsDetailed.findIndex(tag => tag.id === payload.id)
    if (indexTwo !== -1) {
      state.tagsDetailed.splice(indexTwo, 1, payload)
    }
  },
  deleteTag (state, payload) {
    const indexOne = state.tags.findIndex(tag => tag.id === payload.id)
    if (indexOne !== -1) {
      state.tags.splice(indexOne, 1)
    }
    const indexTwo = state.tagsDetailed.findIndex(tag => tag.id === payload.id)
    if (indexTwo !== -1) {
      state.tagsDetailed.splice(indexTwo, 1)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
