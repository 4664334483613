import LENUM from '@/shared/enums/LocalLoad'
import axios from 'axios'
import { isLoggedIn } from '../../shared/utils/auth'

const state = {
  apiState: LENUM.INIT,
  allImages: [],
  imageWords: [],
  targetImages: [],
  distractImages: [],
  imageFilters: [{ filter: 'distractor', filterGroup: 'distract', filterType: 'include' }]
}

const getters = {
  apiState: state => state.apiState !== 3,
  allImages: state => state.allImages,
  imagesForItem: state => word => {
    return state.imageWords.filter(image => image.word === word)
  },
  targetCount: (state) => state.targetImages.length,
  distractCount: (state) => state.distractImages.length,
  targetFilters: state => state.imageFilters.filter(filter => filter.filterGroup === 'target'),
  distractFilters: state => state.imageFilters.filter(filter => filter.filterGroup === 'distract'),
  currentImage: state => id => {
    return state.allImages.filter(image => image.id === id)[0]
  }
}

const actions = {
  async fetchImages ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = await axios.get('/api/v1/image-tags')
        commit('setImages', response.data)
        commit('calculateImages')
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async uploadImages ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      const hasPermission = (await axios.get('/api/v1/check-permission')).data
      if (hasPermission) {
        try {
          commit('setApiState', LENUM.LOADING)
          const response = await axios.post('/api/v1/eportal/images', data, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          data.id = response.data
          commit('addImage', data)
          dispatch(
            'snackbar/setSnackbar',
            {
              icon: 'mdi-check',
              text: 'Success: Image(s) have been uploaded.',
              color: 'success darken-2'
            },
            { root: true }
          )
          commit('setApiState', LENUM.LOADED)
        } catch (error) {
          console.log(error)
          commit('setApiState', LENUM.ERROR)
          dispatch(
            'snackbar/setSnackbar',
            {
              icon: 'mdi-alert',
              text: 'Error: Unable to upload image(s) data.',
              color: 'error darken-2'
            },
            { root: true }
          )
        }
      }
    }
  },
  async fetchImageWords ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = await axios.get('/api/v1/image-words')
        commit('setImageWords', response.data)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async addTagsToImage ({ commit, dispatch }, tags) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.post('/api/v1/eportal/image-tags', tags)
        commit('addTagsToImage', tags)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-check',
            text: 'Success: Tags added to image.',
            color: 'success darken-2'
          },
          { root: true }
        )
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', LENUM.ERROR)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-alert',
            text: 'Error: Something went wrong adding tags to this image.',
            color: 'error darken-2'
          },
          { root: true }
        )
      }
    }
  },
  async removeTagsFromImage ({ commit, dispatch }, tags) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.post('/api/v1/eportal/image-tags/del', tags)
        commit('removeTagsFromImage', tags)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-check',
            text: 'Success: Tags removed from image.',
            color: 'success darken-2'
          },
          { root: true }
        )
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        console.log(error)
        commit('setApiState', LENUM.ERROR)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-alert',
            text: 'Error: Something went wrong removing tags from this image.',
            color: 'error darken-2'
          },
          { root: true }
        )
      }
    }
  },
  // Receptive Identification actions
  async addFilter ({ commit }, payload) {
    await commit('addFilter', payload)
    commit('calculateImages')
  },
  delFilter ({ commit }, payload) {
    commit('delFilter', payload)
    commit('calculateImages')
  },
  updateFilter ({ commit }, payload) {
    commit('updateFilter', payload)
    commit('calculateImages')
  },
  clearFilters ({ commit }) {
    commit('clearFilters')
    commit('calculateImages')
  }
}

const mutations = {
  setApiState (state, payload) {
    state.apiState = payload
  },
  setImages (state, payload) {
    state.allImages = payload
  },
  setImageWords (state, payload) {
    state.imageWords = payload
  },
  addImage (state, payload) {
    state.allImages.push(payload)
  },
  addTagsToImage (state, payload) {
    const index = state.allImages.findIndex(image => image.id === payload.image_id)
    const existing = JSON.parse(state.allImages[index].tags)
    const newTags = existing.concat(payload.tags)
    state.allImages[index].tags = JSON.stringify(newTags)
  },
  removeTagsFromImage (state, payload) {
    const index = state.allImages.findIndex(image => image.id === payload.image_id)
    const existing = JSON.parse(state.allImages[index].tags)
    const newTags = existing.filter(tag => !payload.tags.includes(tag))
    state.allImages[index].tags = JSON.stringify(newTags)
  },
  addFilter (state, payload) {
    let index = -1
    index = state.imageFilters.findIndex(
      filter => filter.filter === payload.filter && filter.filterGroup === payload.filterGroup
    )
    if (index !== -1) {
      const existingFilter = state.imageFilters[index]
      const updatedFilterType = existingFilter.filterType === 'include' ? 'exclude' : 'include'
      state.imageFilters[index].filterType = updatedFilterType
    } else {
      state.imageFilters.push(payload)
    }
  },
  updateFilter (state, payload) {
    let index = -1
    index = state.imageFilters.findIndex(
      filter => filter.filter === payload.filter && filter.filterGroup === payload.filterGroup
    )
    if (index !== -1) {
      const existingFilter = state.imageFilters[index]
      const updatedFilterType = existingFilter.filterType === 'include' ? 'exclude' : 'include'
      state.imageFilters[index].filterType = updatedFilterType
    }
  },
  delFilter (state, payload) {
    let index = -1
    index = state.imageFilters.findIndex(
      filter => filter.filter === payload.filter && filter.filterGroup === payload.filterGroup
    )
    if (index !== -1) {
      state.imageFilters.splice(index, 1)
    }
  },
  clearFilters (state) {
    state.imageFilters = [{ filter: 'distractor', filterGroup: 'distract', filterType: 'include' }]
  },
  calculateImages (state) {
    const targetIncludes = state.imageFilters.filter(
      filter => filter.filterType === 'include' && filter.filterGroup === 'target'
    )
    const targetExcludes = state.imageFilters.filter(
      filter => filter.filterType === 'exclude' && filter.filterGroup === 'target'
    )
    const distractIncludes = state.imageFilters.filter(
      filter => filter.filterType === 'include' && filter.filterGroup === 'distract'
    )
    const distractExcludes = state.imageFilters.filter(
      filter => filter.filterType === 'exclude' && filter.filterGroup === 'distract'
    )

    let targetImages = state.allImages.filter(image => {
      return image.tags
        ? targetExcludes.every(filter => (filter.filter !== '' ? !image.tags.includes(filter.filter) : false))
        : ''
    })

    targetImages = targetImages.filter(image => {
      return image.tags
        ? targetIncludes.every(filter => (filter.filter !== '' ? image.tags.includes(filter.filter) : true))
        : ''
    })

    let distractImages = state.allImages.filter(image => {
      return image.tags
        ? distractExcludes.every(filter => (filter.filter !== '' ? !image.tags.includes(filter.filter) : false))
        : ''
    })

    distractImages = distractImages.filter(image => {
      return image.tags
        ? distractIncludes.every(filter => (filter.filter !== '' ? image.tags.includes(filter.filter) : true))
        : ''
    })

    state.targetImages = targetImages
    state.distractImages = distractImages
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
