import { isLoggedIn } from '@/shared/utils/auth'
import LENUM from '@/shared/enums/LocalLoad'
import axios from 'axios'

const state = {
  apiState: LENUM.INIT,
  apiErrors: null,
  allTeams: [],
  currentTeam: {},
  dataLearnersTeams: [],
  learnerTeams: [],
  userTeams: [],
  allTeamMembers: [],
  currentTeamMembers: [],
  currentTeamLearners: []
}

const getters = {
  apiState: state => state.apiState,
  apiErrors: state => state.apiErrors,
  allTeams: state => state.allTeams,
  allTeamMembers: state => state.allTeamMembers,
  dataLearnersTeams: state => state.dataLearnersTeams,
  learnerTeams: state => state.learnerTeams,
  userTeams: state => state.userTeams,
  currentTeam: state => state.currentTeam,
  currentTeamMembers: state => state.currentTeamMembers,
  currentTeamLearners: state => state.currentTeamLearners.filter((learner) => !!learner)
}

const actions = {
  async fetchTeams ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        // eslint-disable-next-line no-undef
        const teams = await axios.get('/api/v1/organization/teams')
        commit('setTeams', teams.data)
        commit('setApiState', LENUM.LOADED)
        // return response.data
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async setUserTeams ({ commit, dispatch }, id) {
    if (isLoggedIn()) {
      if (state.allTeamMembers.length === 0) {
        await dispatch('fetchAllTeamMembers')
      }
      if (state.dataLearnersTeams.length === 0) {
        await dispatch('fetchDataLearnersTeams')
      }
      commit('setUserTeams', id)
    }
  },
  async fetchDataLearnersTeams ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        // eslint-disable-next-line no-undef
        const response = await axios.get('/api/v1/organization/learners-teams')
        commit('setDataLearnersTeams', response.data)
        commit('setApiState', LENUM.LOADED)
        // return response.data
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async fetchAllTeamMembers ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        commit('setApiErrors', null)
        const response = await axios.get('/api/v1/organization/team-members')
        commit('setAllTeamMembers', response.data)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        // console.log(error)
        const errors = error.response && error.response.data.errors
        commit('setApiErrors', errors)
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async setCurrentTeam ({ commit, dispatch, getters }, id) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        commit('setApiErrors', null)
        if (getters.allTeams.length === 0) {
          await dispatch('fetchTeams')
        }
        commit('setCurrentTeam', id)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        // console.log(error)
        const errors = error.response && error.response.data.errors
        commit('setApiErrors', errors)
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async setCurrentTeamMembers ({ commit, dispatch, getters }, id) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        commit('setApiErrors', null)
        if (getters.allTeamMembers.length === 0) {
          await dispatch('fetchAllTeamMembers')
        }
        commit('setCurrentTeamMembers', id)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        // console.log(error)
        const errors = error.response && error.response.data.errors
        commit('setApiErrors', errors)
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async setCurrentTeamLearners ({ commit, dispatch, getters, rootGetters }, id) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        commit('setApiErrors', null)
        if (getters.dataLearnersTeams.length === 0) {
          await dispatch('fetchDataLearnersTeams')
        }
        var allLearners = rootGetters['learners/allLearners']
        if (allLearners.length === 0) {
          await dispatch('learners/fetchLearners', {}, { root: true })
        }
        allLearners = rootGetters['learners/allLearners']
        const payload = {
          id: id,
          allLearners: allLearners
        }
        commit('setCurrentTeamLearners', payload)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        // console.log(error)
        const errors = error.response && error.response.data.errors
        commit('setApiErrors', errors)
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async setLearnerTeams ({ commit, dispatch, getters }, id) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        if (getters.allTeams.length === 0) {
          await dispatch('fetchTeams')
        }
        if (getters.dataLearnersTeams.length === 0) {
          await dispatch('fetchDataLearnersTeams')
        }
        commit('setLearnerTeams', id)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        // console.log(error)
        const errors = error.response && error.response.data.errors
        commit('setApiErrors', errors)
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async updateTeamDetails ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.patch(`/api/v1/organization/teams/${data.team_id}/update`, data)
        commit('setUpdatedTeamDetails', data)
        commit('setCurrentTeam', data.team_id)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Team details have been updated.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        // console.log(error)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to update team details.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async addUserToTeam ({ commit, dispatch, getters }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const exists = getters.currentTeamMembers.filter(member => member.user_id === data.user_id)
        if (exists.length === 0) {
          await axios.post('/api/v1/organization/team-members', data)
          commit('addUserToTeam', data)
          // commit('setCurrentTeam', data.team_id)
          dispatch('snackbar/setSnackbar', {
            icon: 'mdi-check',
            text: 'Success: User added to team.',
            color: 'success darken-2'
          }, { root: true })
        } else {
          dispatch('snackbar/setSnackbar', {
            icon: 'mdi-alert',
            text: 'Exists: User is a member of this team.',
            color: 'info darken-2'
          }, { root: true })
        }
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to add user to team.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async removeUserFromTeam ({ commit, dispatch, getters }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const exists = getters.currentTeamMembers.filter(member => member.user_id === data.user_id)
        const isLeader = getters.currentTeam.leader_id === data.user_id
        if (exists.length > 0) {
          if (isLeader) {
            dispatch('snackbar/setSnackbar', {
              icon: 'mdi-alert',
              text: 'Error: This user is the team leader, change team leader first.',
              color: 'error darken-2'
            }, { root: true })
          } else {
            await axios.post('/api/v1/organization/team-members', data)
            commit('delUserFromTeam', data)
            // commit('setCurrentTeam', data.team_id)
            dispatch('snackbar/setSnackbar', {
              icon: 'mdi-check',
              text: 'Success: User removed from team.',
              color: 'success darken-2'
            }, { root: true })
          }
        } else {
          dispatch('snackbar/setSnackbar', {
            icon: 'mdi-alert',
            text: 'Exists: User is not a member of this team.',
            color: 'info darken-2'
          }, { root: true })
        }
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        // console.log(error)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to remove user to team.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async addTeamToLearner ({ commit, dispatch, state, getters, rootGetters }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const exists = state.dataLearnersTeams.filter((learners) => learners.learner_id === data.learner_id).filter((teams) => teams.team_id === data.team_id)
        const currentLearner = rootGetters['learners/allLearners'].filter(learner => learner.id === data.learner_id)[0].name
        const newTeam = getters.allTeams.filter(team => team.id === data.team_id)[0].name
        const userId = rootGetters['user/userDetails'].id
        if (exists.length === 0) {
          await axios.post('/api/v1/organization/learner-teams', data)
          commit('addTeamToLearner', data)
          commit('setLearnerTeams', data.learner_id)
          commit('setUserTeams', userId)
          dispatch('setCurrentTeamLearners', data.team_id)
          if (state.userTeams.includes(data.team_id)) {
            dispatch('learners/addLearnerToUserLearners', data.learner_id, { root: true })
          }
          dispatch('snackbar/setSnackbar', {
            icon: 'mdi-check',
            text: `Success: ${currentLearner} is now a part of ${newTeam}`,
            color: 'success darken-2'
          }, { root: true })
        } else {
          dispatch('snackbar/setSnackbar', {
            icon: 'mdi-alert',
            text: `Error: ${currentLearner} is already a part of ${newTeam}`,
            color: 'error darken-2'
          }, { root: true })
        }
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to add learner to team.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async removeTeamFromLearner ({ commit, getters, rootGetters, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const exists = state.dataLearnersTeams.filter((learners) => learners.learner_id === data.learner_id).filter((teams) => teams.team_id === data.team_id)
        const currentLearner = rootGetters['learners/allLearners'].filter(learner => learner.id === data.learner_id)[0].name
        const newTeam = getters.allTeams.filter(team => team.id === data.team_id)[0].name
        if (exists.length > 0) {
          await axios.post('/api/v1/organization/learner-teams/remove', data)
          commit('removeTeamFromLearner', data)
          commit('setLearnerTeams', data.learner_id)
          dispatch('setCurrentTeamLearners', data.team_id)
          dispatch('learners/removeLearnerToUserLearners', data.learner_id, { root: true })
          dispatch('snackbar/setSnackbar', {
            icon: 'mdi-check',
            text: `Success: ${currentLearner} is no longer a part of ${newTeam}`,
            color: 'success darken-2'
          }, { root: true })
        } else {
          dispatch('snackbar/setSnackbar', {
            icon: 'mdi-alert',
            text: `Error: ${currentLearner} is not a part of ${newTeam}`,
            color: 'error darken-2'
          }, { root: true })
        }
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to remove learner to team.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async createOrganizationTeam ({ commit, dispatch, getters }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const responseNewTeam = await axios.post('/api/v1/organization/teams', data.newTeam)
        const newTeam = {
          id: responseNewTeam.data,
          leader: data.newTeam.leader_name,
          leader_id: data.newTeam.leader_id,
          name: data.newTeam.team_name
        }
        if (getters.allTeams.length > 0) {
          commit('addNewTeam', newTeam)
        }
        const batchData = {
          new_team: responseNewTeam.data,
          new_members: data.newTeamMembers
        }
        await axios.post('/api/v1/organization/team-members/batch', batchData)
        // console.log(responseAddTeamMembers)
        // if (getters.dataLearnersTeams.length > 0) {
        //   const newTeamMembers = data.newTeamMembers.map(member => { return { } })
        //   commit('addTeamMembersBatch', newTeamMembers)
        // }
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to create team.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async deleteOrganizationTeam ({ commit, dispatch }, id) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.post(`/api/v1/organization/teams/${id}/delete`)
        commit('deleteOrganizationTeam', id)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Team removed from organization.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to remove team.',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  }
}

const mutations = {
  setApiState (state, status) {
    state.apiState = status
  },
  setApiErrors (state, errors) {
    state.apiErrors = errors
  },
  setTeams (state, teams) {
    state.allTeams = teams
  },
  setUserTeams (state, id) {
    const teams = state.allTeamMembers.filter((teams) => teams.user_id === id)
    const result = teams.map((teams) => { return teams.team_id })
    state.userTeams = result
  },
  setDataLearnersTeams (state, data) {
    state.dataLearnersTeams = data
  },
  setLearnerTeams (state, id) {
    const teamsList = state.dataLearnersTeams.filter((data) => data.learner_id === id).map((data) => { return data.team_id })
    // console.log(teamsList)
    if (teamsList.length > 0) {
      const teams = state.allTeams.filter((team) => teamsList.includes(team.id))
      state.learnerTeams = teams
    } else {
      state.learnerTeams = []
    }
  },
  setCurrentTeam (state, id) {
    const team = state.allTeams.filter((team) => team.id === id)[0]
    state.currentTeam = team
  },
  addUserToTeam (state, data) {
    state.allTeamMembers.push(data)
    state.currentTeamMembers.push(data)
  },
  delUserFromTeam (state, data) {
    const allTeamMembersIndex = state.allTeamMembers.findIndex(member => member.user_id === data.user_id)
    state.allTeamMembers.splice(allTeamMembersIndex, 1)
    const currentTeamMembersIndex = state.currentTeamMembers.findIndex(member => member.user_id === data.user_id)
    state.currentTeamMembers.splice(currentTeamMembersIndex, 1)
  },
  setUpdatedTeamDetails (state, data) {
    const index = state.allTeams.findIndex(team => team.id === data.team_id)
    const oldData = state.allTeams[index]
    const newData = {
      id: data.team_id === oldData.id ? data.team_id : oldData.id,
      leader: data.leader_name === oldData.leader ? oldData.leader : data.leader_name ? data.leader_name : oldData.leader,
      leader_id: data.leader_id === oldData.leader_id ? oldData.leader_id : data.leader_id ? data.leader_id : oldData.leader_id,
      name: data.team_name === oldData.name ? oldData.name : data.team_name ? data.team_name : oldData.name
    }
    // console.log(newData)
    state.allTeams.splice(index, 1, newData)
  },
  setCurrentTeamMembers (state, id) {
    const teamMembers = state.allTeamMembers.filter((team) => team.team_id === id)
    state.currentTeamMembers = teamMembers
  },
  setCurrentTeamLearners (state, data) {
    const id = data.id
    const allLearners = data.allLearners
    const teamsLearners = state.dataLearnersTeams.filter((team) => team.team_id === id)
    const result = []
    for (const learner of teamsLearners) {
      const learners = allLearners.filter(eachLearner => eachLearner.id === learner.learner_id)[0]
      result.push(learners)
    }
    state.currentTeamLearners = result
  },
  setAllTeamMembers (state, data) {
    state.allTeamMembers = data
  },
  addTeamToLearner (state, data) {
    state.dataLearnersTeams.push(data)
  },
  removeTeamFromLearner (state, data) {
    const team = data.team_id
    const learner = data.learner_id
    const index = state.dataLearnersTeams.findIndex(data => data.learner_id === learner && data.team_id === team)
    if (index !== -1) {
      state.dataLearnersTeams.splice(index, 1)
    }
  },
  addNewTeam (state, data) {
    state.allTeams.push(data)
  },
  deleteOrganizationTeam (state, id) {
    state.dataLearnersTeams = state.dataLearnersTeams.filter(data => data.team_id !== id)
    state.allTeams = state.allTeams.filter(team => team.id !== id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
