import LENUM from '@/shared/enums/LocalLoad'
import { isLoggedIn } from '../../shared/utils/auth'
import axios from 'axios'

const state = {
  apiState: LENUM.INIT,
  tutorials: []
}

const getters = {
  learnersTutorials: state => state.tutorials.filter((tutorial) => tutorial.category === 'Learners'),
  organizationTutorials: state => state.tutorials.filter((tutorial) => tutorial.category === 'Organization'),
  skillsTutorials: state => state.tutorials.filter((tutorial) => tutorial.category === 'Skills'),
  teamsTutorials: state => state.tutorials.filter((tutorial) => tutorial.category === 'Teams'),
  toolsTutorials: state => state.tutorials.filter((tutorial) => tutorial.category === 'Tools'),
  tutorial: (state) => (topic) => { return state.tutorials.filter((tutorial) => tutorial.topic === topic)[0] }
}

const actions = {
  async fetchTutorials ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        // eslint-disable-next-line no-undef
        const tutorials = await axios.get('/api/v1/tutorials')
        commit('setTutorials', tutorials.data)
        commit('setApiState', LENUM.LOADED)
        // return response.data
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  }
}

const mutations = {
  setApiState (state, status) {
    state.apiState = status
  },
  setTutorials (state, payload) {
    state.tutorials = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
