import VueRouter from 'vue-router'
// import NProgress from 'nprogress'

import store from '../store'
// import { currentLearner, clearCurrentLearner } from '@/shared/utils/learner'
import { clearCurrentTeam } from '@/shared/utils/team'
// import { logIn } from '../shared/utils/auth'

// import '../../../node_modules/nprogress/nprogress.css'

const routes = [
  ///
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "user-dashboard" */ '@/views/app/dashboard/Dashboard.vue'),
    meta: { layout: 'dashboard', title: 'Dashboard', requiresAuth: true }
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import(/* webpackChunkName: "legal" */ '@/views/app/legal/Index.vue'),
    meta: { layout: 'default', title: 'Terms and Privacy' }
  },
  {
    path: '/subscription-expired',
    name: 'subscription-expired',
    component: () =>
      import(/* webpackChunkName: "subscription-expired" */ '@/views/errors/subscription/SubscriptionExpired.vue'),
    meta: { layout: 'login', title: 'Error' }
  },
  {
    path: '/register',
    component: () => import(/* webpackChunkName: "registration-container" */ '@/views/app/auth/register/Index.vue'),
    meta: { layout: 'login', title: 'Register', forGuest: true },
    children: [
      {
        path: '',
        name: 'registration',
        component: () => import(/* webpackChunkName: "registration-new" */ '@/views/app/auth/register/Register.vue'),
        meta: { layout: 'login', title: 'Register', forGuest: true }
      },
      {
        path: ':id',
        component: () =>
          import(/* webpackChunkName: "registration-organization" */ '@/views/app/auth/register/Register.vue'),
        meta: { layout: 'login', title: 'Register', forGuest: true }
      }
    ]
  },
  {
    path: '/subscribe',
    name: 'registration-subscription',
    component: () =>
      import(/* webpackChunkName: "registration-subscribe" */ '@/views/app/auth/register/Subscription.vue'),
    meta: { layout: 'login', title: 'Select Subscription', requiresAuth: true }
  },
  {
    path: '/subscribe/payment/:plan',
    name: 'registration-payment',
    component: () => import(/* webpackChunkName: "registration-payment" */ '@/views/app/billing/Payment.vue'),
    meta: { layout: 'login', title: 'Payment', requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "user-login" */ '@/views/app/auth/Login.vue'),
    meta: { layout: 'login', title: 'Log in', forGuest: true }
  },
  {
    path: '/login-thread',
    name: 'login-thread',
    component: () => import(/* webpackChunkName: "user-login-thread" */ '@/views/app/auth/LoginThread.vue'),
    meta: { layout: 'login', title: 'Log in', forGuest: true }
  },
  {
    path: '/password/reset',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "password-container" */ '@/views/app/auth/password/Container.vue'),
    meta: { layout: 'login', title: 'Password Reset', forGuest: true }
    // children: [
    //   {
    //     path: ':token',
    //     name: 'password-reset',
    //     component: () => import(/* webpackChunkName: "password-reset" */ '@/views/app/auth/password/PasswordReset.vue'),
    //     meta: { layout: 'login', title: 'Password Reset', forGuest: true }
    //   },
    //   {
    //     path: '',
    //     name: 'forgot-password',
    //     component: () => import(/* webpackChunkName: "forgot-password" */ '@/views/app/auth/password/ForgotPassword.vue'),
    //     meta: { layout: 'login', title: 'Forgot Password', forGuest: true }
    //   }
    // ]
  },

  {
    path: '/account',
    component: () => import(/* webpackChunkName: "user-account-container" */ '@/views/app/account/Index.vue'),
    meta: { layout: 'dashboard', title: 'Account', requiresAuth: true },
    children: [
      {
        path: '',
        name: 'user-account-overview',
        component: () => import(/* webpackChunkName: "user-account-overview" */ '@/views/app/account/Overview.vue'),
        meta: { layout: 'dashboard', title: 'Account', requiresAuth: true }
      },
      {
        path: 'details',
        name: 'user-account-details',
        component: () => import(/* webpackChunkName: "user-account-details" */ '@/views/app/account/Details.vue'),
        meta: { layout: 'dashboard', title: 'Account', requiresAuth: true }
      },
      {
        path: 'billing',
        name: 'user-account-billing',
        component: () => import(/* webpackChunkName: "user-account-billing" */ '@/views/app/account/Billing.vue'),
        meta: { layout: 'dashboard', title: 'Account', requiresAuth: true }
      },
      {
        path: 'subscription',
        name: 'user-account-subscription',
        component: () =>
          import(/* webpackChunkName: "user-account-subscription" */ '@/views/app/account/Subscription.vue'),
        meta: {
          layout: 'dashboard',
          title: 'Account: Subscription',
          requiresAuth: true
        }
      },
      {
        path: 'security',
        name: 'user-account-security',
        component: () => import(/* webpackChunkName: "user-account-security" */ '@/views/app/account/Security.vue'),
        meta: { layout: 'dashboard', title: 'Account', requiresAuth: true }
      },
      {
        path: 'organization',
        component: () =>
          import(/* webpackChunkName: "user-organization" */ '@/views/app/account/organization/Index.vue'),
        meta: {
          layout: 'dashboard',
          title: 'Organization',
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'user-manage-organization',
            component: () =>
              import(/* webpackChunkName: "user-organization-manage" */ '@/views/app/account/organization/Manager.vue'),
            meta: {
              layout: 'dashboard',
              title: 'Organization',
              requiresAuth: true
            }
          },
          {
            path: 'details',
            name: 'change-organization-details',
            component: () =>
              import(
                /* webpackChunkName: "change-organization-details" */ '@/views/app/account/organization/Details.vue'
              ),
            meta: {
              layout: 'dashboard',
              title: 'Organization Details',
              requiresAuth: true
            }
          },
          {
            path: 'member/:id',
            name: 'edit-organization-member',
            component: () =>
              import(
                /* webpackChunkName: "user-organization-member-edit" */ '@/views/app/account/organization/Member.vue'
              ),
            meta: {
              layout: 'dashboard',
              title: 'Edit Member',
              requiresAuth: true
            }
          }
        ]
      }
    ]
  },

  {
    path: '/dashboard',
    name: 'user-dashboard',
    component: () => import(/* webpackChunkName: "user-dashboard" */ '@/views/app/dashboard/Dashboard.vue'),
    meta: { layout: 'dashboard', requiresAuth: true }
  },
  {
    path: '/team',
    component: () => import(/* webpackChunkName: "team-container" */ '@/views/app/dashboard/teams/Index.vue'),
    meta: { layout: 'dashboard', title: 'Team', requiresAuth: true },
    children: [
      {
        path: 'list',
        name: 'user-team',
        component: () => import(/* webpackChunkName: "team-user-list" */ '@/views/app/dashboard/teams/Team.vue'),
        meta: {
          layout: 'dashboard',
          title: 'Teams',
          requiresAuth: true,
          teamSpecific: false
        }
      },
      {
        path: 'details/:id',
        name: 'user-team-details',
        component: () => import(/* webpackChunkName: "team-details" */ '@/views/app/dashboard/teams/EditTeam.vue'),
        meta: {
          layout: 'dashboard',
          title: 'Team Details',
          requiresAuth: true,
          teamSpecific: true
        }
      },
      {
        path: 'learners/:id',
        name: 'user-team-learners',
        component: () => import(/* webpackChunkName: "team-learners" */ '@/views/app/dashboard/teams/Learners.vue'),
        meta: { layout: 'dashboard', title: 'Team Learners' }
      }
    ]
  },
  {
    path: '/learner',
    component: () => import(/* webpackChunkName: "learner-container" */ '@/views/app/dashboard/learners/Index.vue'),
    meta: { layout: 'dashboard', title: 'Learners', requiresAuth: true },
    children: [
      {
        path: 'list',
        name: 'user-learners',
        component: () => import(/* webpackChunkName: "learner-list" */ '@/views/app/dashboard/learners/Learner.vue'),
        meta: {
          layout: 'dashboard',
          title: 'Learners',
          requiresAuth: true,
          learnerSpecific: false
        }
      },
      // separate learners and learner
      {
        path: 'profile/:id',
        name: 'user-learner-edit',
        component: () =>
          import(/* webpackChunkName: "learner-profile" */ '@/views/app/dashboard/learners/EditLearner.vue'),
        meta: {
          layout: 'dashboard',
          title: 'Edit Learner',
          requiresAuth: true,
          learnerSpecific: true
        }
      },
      {
        path: 'tools/:id',
        name: 'user-learner-tools',
        component: () => import(/* webpackChunkName: "learner-tools" */ '@/views/app/dashboard/learners/Tools.vue'),
        meta: {
          layout: 'dashboard',
          title: 'Tools',
          requiresAuth: true,
          learnerSpecific: true
        }
      },
      {
        path: 'targets/:id',
        component: () =>
          import(/* webpackChunkName: "learner-targets-container" */ '@/views/app/dashboard/learners/TargetIndex.vue'),
        meta: {
          layout: 'dashboard',
          title: 'Targets',
          requiresAuth: true,
          learnerSpecific: true
        },
        children: [
          {
            path: '',
            name: 'user-learner-targets',
            component: () =>
              import(/* webpackChunkName: "learner-targets-list" */ '@/views/app/dashboard/learners/Targets.vue'),
            meta: {
              layout: 'dashboard',
              title: 'Targets',
              requiresAuth: true,
              learnerSpecific: true
            }
          },
          {
            path: ':class',
            name: 'user-learner-targets-class',
            component: () =>
              import(
                /* webpackChunkName: "learner-targets-list-class" */ '@/views/app/dashboard/learners/TargetsClass.vue'
              ),
            meta: {
              layout: 'dashboard',
              title: 'Targets by Class',
              requiresAuth: true,
              learnerSpecific: true
            }
          },
          {
            path: ':class/:item',
            name: 'user-learner-targets-item',
            component: () =>
              import(
                /* webpackChunkName: "learner-targets-list-item" */ '@/views/app/dashboard/learners/TargetsItem.vue'
              ),
            meta: {
              layout: 'dashboard',
              title: 'Targets by Class',
              requiresAuth: true,
              learnerSpecific: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/:id/tools',
    component: () => import(/* webpackChunkName: "tools-container" */ '@/views/app/tools/Index.vue'),
    meta: { requiresAuth: true, learnerSpecific: true },
    children: [
      {
        path: 'vm',
        component: () => import(/* webpackChunkName: "vm-container" */ '@/views/app/tools/vm/Index.vue'),
        meta: { requiresAuth: true, learnerSpecific: true },
        children: [
          {
            path: 'new',
            name: 'cl-vm-start',
            component: () => import(/* webpackChunkName: "vm-new" */ '@/views/tools/vm/learner/Index.vue'),
            meta: {
              title: 'Verbal Matrix',
              layout: 'vm',
              requiresAuth: true,
              learnerSpecific: true
            }
          },
          {
            path: 'list/:list',
            name: 'cl-vm-list',
            component: () => import(/* webpackChunkName: "vm-list" */ '@/views/tools/vm/learner/List.vue'),
            meta: {
              title: 'Verbal Matrix',
              layout: 'vm',
              requiresAuth: true,
              learnerSpecific: true
            }
          },
          {
            path: 'list/:list/filter/:filter',
            name: 'cl-vm-list-filter',
            component: () =>
              import(/* webpackChunkName: "vm-list-filter" */ '@/views/tools/vm/learner/ListFiltered.vue'),
            meta: {
              title: 'Verbal Matrix',
              layout: 'vm',
              requiresAuth: true,
              learnerSpecific: true
            }
          },
          {
            path: 'item/:item',
            name: 'cl-vm-item',
            component: () => import(/* webpackChunkName: "vm-item" */ '@/views/tools/vm/learner/Item.vue'),
            props: true,
            meta: {
              title: 'Verbal Matrix',
              layout: 'vm',
              requiresAuth: true,
              learnerSpecific: true
            }
          }
        ]
      },
      {
        path: 'rise',
        component: () => import(/* webpackChunkName: "rise-container" */ '@/views/app/tools/ri/Index.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'new',
            name: 'cl-rise-start',
            component: () => import(/* webpackChunkName: "rise-new" */ '@/views/tools/ri/rise/learner/Index.vue'),
            meta: {
              title: 'RISE',
              layout: 'dashboard',
              requiresAuth: true,
              learnerSpecific: true
            }
          },
          {
            path: 'session/:exemplar',
            name: 'cl-rise-session',
            component: () => import(/* webpackChunkName: "rise-session" */ '@/views/tools/ri/rise/learner/Session.vue'),
            meta: {
              title: 'RISE',
              layout: 'ri',
              requiresAuth: true,
              learnerSpecific: true
            }
          }
        ]
      },
      {
        path: 'rime',
        component: () => import(/* webpackChunkName: "rime-container" */ '@/views/app/tools/ri/Index.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'new',
            name: 'cl-rime-start',
            component: () => import(/* webpackChunkName: "rime-new" */ '@/views/tools/ri/rime/learner/Index.vue'),
            meta: {
              title: 'RIME',
              layout: 'dashboard',
              requiresAuth: true,
              learnerSpecific: true
            }
          },
          {
            path: 'session',
            name: 'cl-rime-session',
            component: () => import(/* webpackChunkName: "rime-session" */ '@/views/tools/ri/rime/learner/Session.vue'),
            meta: {
              title: 'RIME',
              layout: 'ri',
              requiresAuth: true,
              learnerSpecific: true
            }
          }
        ]
      },
      {
        path: 'skills/:skill',
        component: () => import(/* webpackChunkName: "skills-container" */ '@/views/app/tools/skills/Index.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'session',
            name: 'cl-skill-session',
            component: () =>
              import(/* webpackChunkName: "skills-session" */ '@/views/tools/skills/learner/Session.vue'),
            meta: {
              title: 'Skills',
              layout: 'ri',
              requiresAuth: true,
              learnerSpecific: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/skills',
    component: () => import(/* webpackChunkName: "skill-builder-container" */ '@/views/app/skills/Index.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'builder',
        name: 'skills-form',
        component: () => import(/* webpackChunkName: "skill-builder-form" */ '@/views/app/skills/builder/Index.vue'),
        meta: {
          title: 'Skill Builder',
          layout: 'dashboard',
          requiresAuth: true
        }
      },
      {
        path: ':id/editor',
        name: 'skills-editor-form',
        component: () => import(/* webpackChunkName: "skill-builder-editor" */ '@/views/app/skills/editor/Index.vue'),
        meta: { title: 'Skill Editor', layout: 'dashboard', requiresAuth: true }
      }
    ]
  },
  {
    path: '/resources',
    component: () =>
      import(/* webpackChunkName: "organization-resources-container" */ '@/views/app/dashboard/resources/Index.vue'),
    meta: { layout: 'dashboard', requiresAuth: true },
    children: [
      {
        path: 'skills',
        name: 'resources-skills',
        component: () =>
          import(
            /* webpackChunkName: "organization-resources-skills" */ '@/views/app/dashboard/resources/skills/Index.vue'
          ),
        meta: { title: 'Skills', layout: 'dashboard', requiresAuth: true }
      },
      {
        path: 'forms',
        name: 'resources-forms',
        component: () =>
          import(
            /* webpackChunkName: "organization-resources-forms" */ '@/views/app/dashboard/resources/forms/Index.vue'
          ),
        meta: { title: 'Forms', layout: 'dashboard', requiresAuth: true }
      },
      {
        path: 'core-tools',
        name: 'resources-core-tools',
        component: () =>
          import(
            /* webpackChunkName: "organization-resources-tools" */ '@/views/app/dashboard/resources/tools/Index.vue'
          ),
        meta: { title: 'Core Tools', layout: 'dashboard', requiresAuth: true }
      }
    ]
  },
  {
    path: '/reports',
    name: 'user-reports',
    component: () =>
      import(/* webpackChunkName: "organization-resources-reports" */ '@/views/app/dashboard/Reports.vue'),
    meta: { layout: 'dashboard', requiresAuth: true }
  },
  {
    path: '/tutorials',
    component: () => import(/* webpackChunkName: "tutorials-container" */ '@/views/app/tutorials/Container.vue'),
    meta: { layout: 'dashboard' },
    children: [
      {
        path: '',
        name: 'tutorials',
        component: () => import(/* webpackChunkName: "tutorials-index" */ '@/views/app/tutorials/Index.vue'),
        meta: { title: 'Tutorials', layout: 'dashboard', requiresAuth: true }
      },
      {
        path: ':topic',
        name: 'tutorials-topic',
        component: () => import(/* webpackChunkName: "tutorials-topic" */ '@/views/app/tutorials/Topic.vue'),
        meta: { title: 'Tutorials', layout: 'dashboard', requiresAuth: true }
      }
    ]
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404-error" */ '@/views/errors/html_response/404.vue'),
    meta: { layout: 'login', title: '404', requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? 'aba|tools | ' + to.meta.title : 'aba|tools'
  next()
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.forGuest)) {
    if (localStorage.isLoggedIn === 'true') {
      next({ name: 'home' })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.isLoggedIn === 'true') {
      const status = JSON.parse(localStorage.organization).status
      const role = JSON.parse(localStorage.user).role
      if (status === 'active' || role === 'Owner') {
        store.dispatch('user/setUser').then(() => next())
      } else {
        store.dispatch('user/logout').then(() => next({ name: 'subscription-expired' }))
      }
    } else {
      store.dispatch('user/logout').then(() => next({ name: 'login', query: { redirect: to.fullPath } }))
      // next()
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.learnerSpecific)) {
    // console.log('learner needs to be loaded')
    if (to.params.id === localStorage.getItem('CLID')) {
      // console.log('match')
      next()
    } else {
      // console.log('mismatch')
      // currentLearner(to.params.id)
      // console.log(to.params.id)
      store.dispatch('learners/setCurrentLearner', to.params.id).then(() => next())
    }
  } else {
    // clearCurrentLearner()
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.teamSpecific)) {
    // console.log('learner needs to be loaded')
    if (to.params.id === localStorage.getItem('CTID')) {
      // console.log('match')
      next()
    } else {
      // console.log('mismatch')
      // currentTeam(to.params.id)
      store.dispatch('teams/setCurrentTeam', to.params.id)
      store.dispatch('teams/setCurrentTeamMembers', to.params.id).then(() => next())
      // store.dispatch('currentTeam/loadTeam').then(() => next())
    }
  } else {
    clearCurrentTeam()
    next()
  }
})

export default router
