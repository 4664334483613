<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
const defaultLayout = 'admin'

export default {
  computed: {
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  }
  // watch: {
  //   $route: {
  //     handler: (to, from) => {
  //       document.title = to.meta.title || 'aba|tools - eportal'
  //     },
  //     immediate: true
  //   }
  // }
}
</script>

<style>

</style>
