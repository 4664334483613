export function isLoggedIn () {
  return JSON.parse(localStorage.isLoggedIn)
}

export function logIn () {
  localStorage.setItem('isLoggedIn', true)
}

export function setSub (sub) {
  localStorage.setItem('sub', sub)
}

export function setQuantity (value) {
  localStorage.setItem('qty', value)
}

export function logOut () {
  localStorage.setItem('isLoggedIn', false)
  localStorage.setItem('user', '')
  localStorage.setItem('organization', '')
}

export function mapPermissions (data) {
  var permissions = data.map((item) => { return { [item.permission]: item.value } })
  permissions = permissions.reduce((r, a) => { return Object.assign(r, a) }, {})
  const result = permissions
  return result
}
