import VueRouter from 'vue-router'
// import NProgress from 'nprogress'

import Test from '../views/index/Index.vue'

// import '../../../node_modules/nprogress/nprogress.css'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Test
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// NProgress.configure({
//   minimum: 0.04,
//   easing: 'ease',
//   positionUsing: '',
//   speed: 200,
//   trickle: true,
//   trickleRate: 0.02,
//   trickleSpeed: 800,
//   showSpinner: false
// })

// router.beforeResolve((to, from, next) => {
//   if (to.name) {
//     NProgress.start()
//   }
//   next()
// })

// router.afterEach(() => {
//   NProgress.done()
// })

export default router
