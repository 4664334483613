export default {
  data () {
    return {

    }
  },
  computed: {
    permission () {
      switch (localStorage.epat) {
        case ('atGoblin'):
          return 0
        case ('atAdmin'):
          return 1
        case ('atUser'):
          return 2
        default:
          return 999
      }
    }
  },
  methods: {

  }
}
