<template>
  <v-btn
    v-show="btnToTop"
    v-scroll="onScroll"
    fab
    elevation="4"
    fixed
    bottom
    right
    class="atTop white--text"
    @click="toTop"
  >
    <v-icon large>
      mdi-chevron-up
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BackToTop',
  data () {
    return {
      btnToTop: false
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') { return }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.btnToTop = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>
/* color="rgba(0,0,0,0.2)" */
.atTop {
  background-color: rgba(0,0,0,0.2) !important;
}
.atTop::before {
  background-color: rgba(0,0,0,0.2) !important;
}
.atTop::after {
  background-color: rgba(0,0,0,0.2) !important;
}
.atTop:hover {
  background-color: #ffcf00 !important;
}
</style>
