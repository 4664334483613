import LENUM from '@/shared/enums/LocalLoad'
import { isLoggedIn } from '../../shared/utils/auth'
import axios from 'axios'

const state = {
  apiState: LENUM.INIT,
  data: []
}

const getters = {
  objectiveTemplatesData: state => state.data,
  assessments: state => [
    ...new Set(
      state.data.map(element => {
        return element.assessment
      })
    )
  ]
}

const mutations = {
  setApiState (state, status) {
    state.apiState = status
  },
  setAllObjectiveTemplateData (state, payload) {
    state.data = payload
  },
  setAllObjectiveTemplateFormData (state, payload) {
    state.formData = payload
  },
  addObjectiveTemplate (state, payload) {
    state.data.push(payload)
  },
  editObjectiveTemplate (state, payload) {
    const index = state.data.findIndex(template => template.id === payload.id)
    const template = Object.assign({}, payload)
    if (index !== -1) {
      state.data.splice(index, 1, template)
    }
  },
  removeObjectiveTemplate (state, payload) {
    const index = state.data.findIndex(template => template.id === payload.id)
    state.data.splice(index, 1)
  }
}

const actions = {
  async fetchObjectiveTemplate ({ commit }) {
    if (isLoggedIn()) {
      commit('setApiState', LENUM.LOADING)
      const data = (await axios.get('/api/v1/eportal/objective-templates')).data
      commit('setAllObjectiveTemplateData', data)
      commit('setApiState', LENUM.LOADED)
    }
  },
  async saveObjectiveTemplate ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        data.sd = JSON.stringify(data.sd)
        const response = (await axios.post('/api/v1/eportal/objective-templates', data)).data
        commit('addObjectiveTemplate', response)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-information',
            text: 'Success: New Template added.',
            color: 'success darken-2'
          },
          { root: true }
        )
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-alert',
            text: 'Error: Something went wrong.',
            color: 'error darken-2'
          },
          { root: true }
        )
      }
    }
  },
  async updateObjectiveTemplate ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        data.sd = JSON.stringify(data.sd)
        await axios.patch(`/api/v1/eportal/objective-templates/${data.id}`, data)
        commit('editObjectiveTemplate', data)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-information',
            text: 'Success: Template edited.',
            color: 'success darken-2'
          },
          { root: true }
        )
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-alert',
            text: 'Error: Something went wrong.',
            color: 'error darken-2'
          },
          { root: true }
        )
      }
    }
  },
  async deleteObjectiveTemplate ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.post(`/api/v1/eportal/objective-templates/${data.id}`)
        commit('removeObjectiveTemplate', data)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-information',
            text: 'Success: Template removed.',
            color: 'success darken-2'
          },
          { root: true }
        )
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch(
          'snackbar/setSnackbar',
          {
            icon: 'mdi-alert',
            text: 'Error: Something went wrong.',
            color: 'error darken-2'
          },
          { root: true }
        )
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
