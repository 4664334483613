
const state = {
  snackbars: []
}

const getters = {
  snackbars: state => state.snackbars.filter(s => s.showing).slice(0).slice(-3)
}

const actions = {
  setSnackbar ({ commit }, snackbar) {
    snackbar.showing = true
    snackbar.color = snackbar.color || 'success'
    snackbar.icon = snackbar.icon || 'mdi-alert'
    commit('setSnackbar', snackbar)
  }
}

const mutations = {
  setSnackbar (state, snackbar) {
    state.snackbars.push(snackbar)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
