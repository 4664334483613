import LENUM from '@/shared/enums/LocalLoad'
import { isLoggedIn } from '../../shared/utils/auth'
import axios from 'axios'

const state = {
  apiState: LENUM.INIT,
  words: [],
  wordSets: []
}

const getters = {
  words: state => state.words,
  items: state => state.words.filter(item => item.word_type === 'item'),
  uniqueWords: state => [...new Set(state.words.map(value => value.word))].map((value, index) => { return { id: index + 1, word: value } }),
  wordSets: state => state.wordSets
}

const actions = {
  async fetchWords ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const words = (await axios.get('/api/v1/words')).data
        commit('setWords', words)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async fetchWordSets ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const wordSets = (await axios.get('/api/v1/word_sets')).data
        commit('setWordSets', wordSets)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  }
}

const mutations = {
  setApiState (state, status) {
    state.apiState = status
  },
  setWords (state, words) {
    state.words = words
  },
  setWordSets (state, wordSets) {
    state.wordSets = wordSets
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
