<template>
  <div>
    <v-snackbar
      v-for="(snackbar, index) in snackbars"
      :key="`snackbar_${index}_${Math.random()}`"
      v-model="snackbar.showing"
      :timeout="snackbar.timeout"
      light
      top
      multi-line
      :style="`top: ${(index * 70) + 10}px`"
    >
      <span class="d-flex align-center">
        <v-icon
          :color="snackbar.color"
          class="mr-2"
        >
          {{ snackbar.icon }}
        </v-icon>
        {{ snackbar.text ? snackbar.text : 'aba|tools' }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="atBlue"
          text
          v-bind="attrs"
          @click="snackbar.showing = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GlobalSnackbar',
  computed: {
    ...mapGetters('snackbar', ['snackbars'])
  }
}
</script>

<style>

</style>
