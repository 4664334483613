<template>
  <v-app>
    <v-main fill-height>
      <router-view />
      <!-- <v-container
        fill-height
        fluid
      >
        <router-view />
      </v-container> -->
    </v-main>
    <back-to-top />
    <big-footer />
    <v-snackbar
      v-for="(snackbar, index) in snackbars"
      :key="`snackbar_${index}_${Math.random()}`"
      v-model="snackbar.showing"
      :timeout="snackbar.timeout"
      light
      top
      multi-line
      :style="`top: ${(index * 70) + 10}px`"
    >
      <span class="d-flex align-center">
        <v-icon
          :color="snackbar.color"
          class="mr-2"
        >
          {{ snackbar.icon }}
        </v-icon>
        {{ snackbar.text ? snackbar.text : 'aba|tools' }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="atBlue"
          text
          v-bind="attrs"
          @click="snackbar.showing = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

import BackToTop from '../components/layout/BackToTop'
import BigFooter from '../components/layout/BigFooter'
// import UserMenu from '../components/layout/UserMenu'

export default {
  name: 'LoginLayout',
  components: {
    BackToTop,
    BigFooter
    // UserMenu
  },
  data () {
    return {
      dashNav: true,
      dashboardNavigation: [
        {
          route: 'user-dashboard',
          icon: 'mdi-view-dashboard',
          color: 'atBlue',
          title: 'Dashboard'
        },
        {
          route: 'user-team',
          icon: 'mdi-account-supervisor',
          color: 'atBlue',
          title: 'Team'
        },
        {
          route: 'user-reports',
          icon: 'mdi-file-multiple',
          color: 'atBlue',
          title: 'Reports'
        },
        {
          route: 'user-resources',
          icon: 'mdi-bookshelf',
          color: 'atBlue',
          title: 'Resources'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('snackbar', ['snackbars'])
  },
  methods: {

  }
}
</script>

<style scoped>
#app {
  /* background: #0f4c81; */
  background: rgb(15,76,129);
  /* background: linear-gradient(108deg, rgba(15,76,129,1) 0%, rgba(0,51,144,1) 100%); */
  background: linear-gradient(10deg, rgba(15,76,129,1) 41%, rgba(0,51,144,1) 100%);

}
</style>
