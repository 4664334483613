<template>
  <v-app>
    <v-app-bar
      app
      clipped-left
      color="atBlue"
      dense
      dark
    >
      <v-app-bar-nav-icon @click.stop="dashNav = !dashNav" />
      <router-link
        :to="{name: 'home'}"
        class="text-decoration-none white--text"
      >
        <v-toolbar-title>aba<span class="atYellow--text">|</span>tools</v-toolbar-title>
      </router-link>
      <v-spacer />
      <user-menu
        v-if="isLoggedIn"
        :avatar="user.initials"
        :office="organization.name"
        :subscription="subscription"
        :username="user.name"
      />
    </v-app-bar>
    <v-navigation-drawer
      v-model="dashNav"
      app
      clipped
      fixed
    >
      <v-list>
        <v-list-item
          two-line
          :three-line="subscription === 'pro'"
        >
          <v-list-item-avatar
            class="atBlue"
          >
            <span class="white--text text-h6">{{ organization.subscription === 1 ? user.initials : organization.initials }}</span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-capitalize">
              {{ organization.subscription === 1 ? user.name : organization.name }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="subscription === 'pro'">
              {{ organization.subscription === 1 ? user.title : user.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="atBlue--text text-uppercase font-weight-bold">
              <small>{{ subscription }}</small>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-group color="atBlue">
          <div
            v-for="(item, i) in navDrawerTop"
            :key="i"
          >
            <v-list-item
              v-if="item.permission"
              :to="{ name: item.route }"
              link
              exact
            >
              <v-list-item-action>
                <v-icon :color="item.color">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                  <v-chip
                    v-if="item.subscription === 'pro' && subscription !== item.subscription"
                    color="atBlue"
                    text-color="white"
                    pill
                    x-small
                  >
                    PRO
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <auto-logout />
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <back-to-top />
    <big-footer />
    <at-tracking />
    <v-snackbar
      v-for="(snackbar, index) in snackbars"
      :key="`snackbar_${index}_${Math.random()}`"
      v-model="snackbar.showing"
      :timeout="snackbar.timeout"
      light
      top
      multi-line
      :style="`top: ${(index * 70) + 10}px`"
    >
      <span class="d-flex align-center">
        <v-icon
          :color="snackbar.color"
          class="mr-2"
        >
          {{ snackbar.icon }}
        </v-icon>
        {{ snackbar.text ? snackbar.text : 'aba|tools' }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="atBlue"
          text
          v-bind="attrs"
          @click="snackbar.showing = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

import BackToTop from '@/components/layout/BackToTop'
import BigFooter from '@/components/layout/BigFooter'
import UserMenu from '@/components/layout/UserMenu'
import AutoLogout from '@/components/auth/AutoLogout'
import AtTracking from '@/components/layout/AtTracking'

export default {
  name: 'DashboardLayout',
  components: {
    AutoLogout,
    AtTracking,
    BackToTop,
    BigFooter,
    UserMenu
  },
  data () {
    return {
      dashNav: false,
      dashLogo: false,
      navDrawerBottom: [
        {
          route: 'user-manage-organization',
          icon: 'mdi-account-supervisor',
          color: 'atBlue',
          title: 'Organization',
          subscription: 'pro'
        },
        {
          route: 'user-account-details',
          icon: 'mdi-account',
          color: 'atBlue',
          title: 'Details',
          subscription: 'free'
        }
      ],
      isLoggedIn: false
    }
  },
  computed: {
    ...mapGetters('snackbar', ['snackbars']),
    ...mapGetters('user', [
      'userDetails',
      'userOrganization',
      'userPermissions'
    ]),
    organization () {
      if (localStorage.organization) {
        const result = JSON.parse(localStorage.organization)
        return result
      } else if (this.userOrganization) {
        return this.userOrganization
      } else {
        return { initials: 'a|t', name: 'aba|tools', subscription: 0 }
      }
    },
    user () {
      if (localStorage.user) {
        const result = JSON.parse(localStorage.user)
        return result
      } else if (this.userDetails) {
        return this.userDetails
      } else {
        return { initials: 'a|t', name: 'aba|tools' }
      }
    },
    subscription () {
      const subscription = Number(this.organization.subscription)
      switch (subscription) {
        case (1):
          return 'free'
        case (2):
          return 'home'
        case (3):
          return 'pro'
        default:
          return 'unauthorized'
      }
    },
    navDrawerTop () {
      const result = [
        {
          route: 'user-dashboard',
          icon: 'mdi-view-dashboard',
          color: 'atBlue',
          title: 'Dashboard',
          subscription: 'free',
          permission: true
        },
        {
          route: 'resources-core-tools',
          icon: 'mdi-toolbox',
          color: 'atBlue',
          title: 'Core Tools',
          subscription: 'free',
          permission: true
        },
        {
          route: 'resources-skills',
          icon: 'mdi-bullseye-arrow',
          color: 'atBlue',
          title: 'Skills',
          subscription: 'free',
          permission: this.userPermissions ? this.userPermissions.visible_skills === 1 : false
        },
        {
          route: 'user-learners',
          icon: 'mdi-school',
          color: 'atBlue',
          title: 'Learners',
          subscription: 'free',
          permission: this.userPermissions ? this.userPermissions.visible_learners === 1 : false
        },
        {
          route: 'user-team',
          icon: 'mdi-account-multiple',
          color: 'atBlue',
          title: 'Teams',
          subscription: 'pro',
          permission: this.userPermissions ? this.userPermissions.visible_teams === 1 : false
        },
        // {
        //   route: 'resources-forms',
        //   icon: 'mdi-bookshelf',
        //   color: 'atBlue',
        //   title: 'Forms',
        //   subscription: 'free',
        //   permission: true
        // },
        {
          route: 'user-account-overview',
          icon: 'mdi-account',
          color: 'atBlue',
          title: 'Account',
          subscription: 'free',
          permission: true
        },
        {
          route: 'user-manage-organization',
          icon: 'mdi-account-supervisor',
          color: 'atBlue',
          title: 'Organization',
          subscription: 'pro',
          permission: this.userPermissions ? this.userPermissions.visible_organization === 1 : false
        },
        {
          route: 'tutorials',
          icon: 'mdi-book-open-variant',
          color: 'atBlue',
          title: 'Tutorials',
          subscription: 'free',
          permission: true
        }
      ]
      return result
    }
  },
  created () {
    if (localStorage.isLoggedIn) {
      this.isLoggedIn = localStorage.isLoggedIn
    }
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.setDashLogo, { passive: true })
    }
  },
  mounted () {
    this.initDashNav()
    window.addEventListener('resize', this.setDashLogo, { passive: true })
  },
  methods: {
    initDashNav () {
      var dash = window.innerWidth > 1264
      this.dashNav = dash
    },
    setDashLogo () {
      var dash = window.innerWidth > 1264
      this.dashLogo = !dash
    },
    createInitials (name) {
      const data = name.split(' ')
      let output = ''

      function howMany () {
        if (data.length > 2) {
          return 2
        } else {
          return data.length
        }
      }

      for (var i = 0; i < howMany(); i++) {
        output += data[i].substring(0, 1)
      }
      return output.toUpperCase()
    }
  }
}
</script>

<style scoped>
.atTitle {
  background: rgb(15,76,129);
  background: linear-gradient(10deg, rgba(15,76,129,1) 41%, rgba(0,51,144,1) 100%);
  /* background: linear-gradient(10deg, rgba(15,76,129,1) 41%, rgba(0,51,144,1) 100%); */
}
</style>
