import Vue from 'vue'
import Vuex from 'vuex'

// import { isLoggedIn } from '@/shared/utils/auth'
// import { getUserInitials, getUserOrgInitials } from '@/shared/utils/user'
// import { getCurrentLearner } from '@/shared/utils/learner'
import currentLearner from './modules/CurrentLearner'
import currentTeam from './modules/CurrentTeam'
import currentUser from './modules/CurrentUser'
// import organization from './modules/Organization_old'
import userLearners from './modules/UserLearners'
import images from './modules/Images'
import learners from './modules/Learners'
import organization from './modules/Organization'
import skills from './modules/Skills'
import skillBuilder from './modules/SkillBuilder'
import snackbar from './modules/Snackbar'
import tags from './modules/Tags'
import teams from './modules/Teams'
import tutorials from './modules/Tutorials'
import user from './modules/User'
import words from './modules/Words'
import therapyStyles from './modules/TherapyStyles'
import objectiveTemplates from './modules/ObjectiveTemplates'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    currentLearner,
    currentTeam,
    currentUser,
    images,
    learners,
    objectiveTemplates,
    organization,
    skills,
    skillBuilder,
    snackbar,
    tags,
    teams,
    therapyStyles,
    tutorials,
    user,
    userLearners,
    words
  },
  state: {},
  getters: {},
  actions: {
    loadStoredState (context) {
      // context.commit('currentUser/setLoggedIn', isLoggedIn())
      // context.commit('currentUser/setUserInitials', getUserInitials())
      // context.commit('currentUser/setUserOrganizationInitials', getUserOrgInitials())
      // context.commit('currentLearner/loadLearner', getCurrentLearner())
    }
  },
  mutations: {}
})
