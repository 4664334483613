export function createInitials (name) {
  // console.log('in utils ' + name)
  const data = name.split(' ')
  let output = ''

  function howMany () {
    if (data.length > 2) {
      return 2
    } else {
      return data.length
    }
  }

  for (var i = 0; i < howMany(); i++) {
    output += data[i].substring(0, 1)
  }

  // localStorage.setItem(localItem, output.toUpperCase())
  return output.toUpperCase()
}

export function localUser (user) {
  user.initials = createInitials(user.name)
  const result = JSON.stringify(user)
  localStorage.setItem('user', result)
}

export function localOrganization (organization) {
  organization.initials = createInitials(organization.name)
  const result = JSON.stringify(organization)
  localStorage.setItem('organization', result)
}

export function getUserInitials () {
  if (localStorage.getItem('userInitials') !== '') {
    return localStorage.getItem('userInitials')
  }
}

export function getUserOrgInitials () {
  if (localStorage.getItem('orgInitials') !== '') {
    return localStorage.getItem('orgInitials')
  }
}
