import { isLoggedIn } from '@/shared/utils/auth'
import { getCurrentTeam } from '@/shared/utils/team'
import GENUM from '@/shared/enums/enums'
import LENUM from '@/shared/enums/LocalLoad'

const state = {
  apiState: GENUM.INIT,
  apiLocalState: LENUM.INIT,
  details: {}
}
const getters = {
}
const actions = {
  loadTeam ({ commit, dispatch }) {
    // commit('apiLocalState', LENUM.LOADING)
    dispatch('teamDetails')
    // dispatch('loadCustomTargets')
    // commit('apiLocalState', LENUM.LOADED)
  },
  async teamDetails ({ commit, rootState }) {
    // console.log('loading learner')
    if (isLoggedIn() && getCurrentTeam() !== '') {
      // console.log('user is auth')
      const teamId = getCurrentTeam()
      try {
        commit('apiLocalState', LENUM.LOADING)
        // eslint-disable-next-line no-undef
        const team = (await axios.get(`/api/v1/organization/teams/${teamId}`)).data
        // console.log(learner)
        commit('setTeamDetails', team)
        commit('apiLocalState', LENUM.LOADED)
      } catch (error) {
        commit('apiLocalState', LENUM.ERROR)
      }
    }
  }
}
const mutations = {
  apiLocalState (state, payload) {
    state.apiLocalState = payload
  },
  setTeamDetails (state, payload) {
    state.details = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
