import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Vuetify from '../plugins/vuetify'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueGtag from 'vue-gtag'

// import router from './router/routes'
import AdminRoutes from './router/AdminRoutes'
import AppRoutes from './router/AppRoutes'
import IndexRoutes from './router/IndexRoutes'
import App from './app/App.vue'
import Admin from './app/Admin.vue'
import store from './store'
import LoadScript from 'vue-plugin-load-script'

// import { UserManager, WebStorageStateStore } from 'oidc-client'

require('./bootstrap')

// window.Vue = require('vue');
const host = window.location.host
const parts = host.split('.')
const domainLength = 3 // route1.example.com => domain length = 3
const router = () => {
  let routes
  if (parts.length === domainLength - 1 || parts[0] === 'www') {
    routes = IndexRoutes
  } else if (parts[0] === 'learning') {
    routes = AppRoutes
  } else if (parts[0] === 'app') {
    routes = AppRoutes
  } else if (parts[0] === 'eportal') {
    routes = AdminRoutes
  } else {
    // If you want to do something else just comment the line below
    routes = IndexRoutes
  }
  return routes
}

Vue.component('default-layout', require('./layouts/Default.vue').default)
Vue.component('empty-layout', require('./layouts/Empty.vue').default)
Vue.component('admin-layout', require('./layouts/Admin.vue').default)
Vue.component('account-layout', require('./layouts/Account.vue').default)
Vue.component('dashboard-layout', require('./layouts/Dashboard.vue').default)
Vue.component('login-layout', require('./layouts/Login.vue').default)
Vue.component('vm-layout', require('./layouts/VerbalMatrix.vue').default)
Vue.component(
  'ri-layout',
  require('./layouts/ReceptiveIdentification.vue').default
)

window.Vue = require('vue')
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(Vuetify)
Vue.use(LoadScript)

var siteKey = null
switch (parts[1]) {
  case 'abatools':
    siteKey = '6LdYl78UAAAAAHiFlZkbu2OU2Ha6Tlkg6Q31Copd'
    break
  case 'verbalmatrix':
    siteKey = '6LeU-nEaAAAAADmbBXHNmL41VTt2n5l-0MpLCPgm'
    break
  case 'abatools-v2':
    siteKey = '6LeC_HEaAAAAAGKtQA77nr8_za2-pmF6Ln2HCu71'
    break
  default:
    siteKey = ''
    break
}

Vue.use(
  VueGtag,
  {
    config: { id: 'UA-144897298-1' },
    bootstrap: false
  },
  router()
)

Vue.use(VueReCaptcha, {
  siteKey: siteKey,
  loaderOptions: {
    autoHideBadge: false
  }
})

window.axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch('user/logout')
      if (parts.length === domainLength - 1 || parts[0] === 'www') {
        AppRoutes.push({ name: 'login' })
      } else if (parts[0] === 'app') {
        AppRoutes.push({ name: 'login' })
      } else if (parts[0] === 'learning') {
        AppRoutes.push({ name: 'login' })
      } else if (parts[0] === 'eportal') {
        AdminRoutes.push({ name: 'eportal-login' })
      } else {
        // If you want to do something else just comment the line below
        AppRoutes.push({ name: 'login' })
      }
    }

    return Promise.reject(error)
  }
)
// const getSettings = () => {
//   const settings = {
//     authority: 'https://thd-dev-stitch.env.centralreach.com/',
//     automaticSilentRenew: true,
//     includeIdTokenInSilentRenew: false,
//     monitorSession: true,
//     query_status_response_type: 'code',
//     client_id: 'ThreadWeb',
//     post_logout_redirect_uri: window.location.origin + '/',
//     redirect_uri: window.location.origin + '/signin-callback.html',
//     response_type: 'code',
//     scope: 'openid profile ThreadApi SpoolApi StitchApi',
//     silent_redirect_uri: window.location.origin + '/silent-renew.html',
//     userStore: new WebStorageStateStore({ store: window.sessionStorage }),
//     loadUserInfo: true,
//     extraQueryParams: {
//       app: 'thread',
//       ts: Date()
//     }
//   }
//   return settings
// }

// const settings = getSettings()
// const userManager = new UserManager(settings)
// userManager.signinRedirect()
// console.log(userManager)

// eslint-disable-next-line no-unused-vars
const app = new Vue({
  vuetify: Vuetify,
  el: '#app',
  router: router(),
  store,
  components: {
    appIndex: App,
    adminIndex: Admin
  },
  async beforeCreate () {
    // store.dispatch('loadStoredState')
    // store.dispatch('currentUser/loadUser')
    // store.dispatch('organization/loadOrganization')
    // store.dispatch('currentTeam/loadTeam')
    // store.dispatch('currentLearner/loadLearner')
    // store.dispatch('learners/fetchLearners')
    // store.dispatch('skills/fetchSkills')
    // store.dispatch('skills/fetchAllLearnerSkills')
  }
})
