<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <v-btn
      v-show="btnToTop"
      v-scroll="onScroll"
      fab
      elevation="4"
      fixed
      bottom
      right
      small
      color="atYellow"
      class="atBlue--text"
      @click="toTop"
    >
      <v-icon large>
        mdi-chevron-up
      </v-icon>
    </v-btn>
  </v-app>
</template>

<script>
export default {
  name: 'EmptyLayout',
  data () {
    return {
      btnToTop: false
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') { return }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.btnToTop = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>
.footer-link {
  /* color: #9E9E9E !important; */
  color: #ffcf00 !important;
}

.footer-link:hover {
  /* color: #ffcf00 !important; */
  text-decoration: underline !important;
}
</style>
