const state = {
  name: 'Ryan Capehart',
  username: 'rcapehart',
  email: 'ryan@abatools.com',
  office: 'ABA tools llc',
  subscription: 'free'
}
const getters = {}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
