<template>
  <v-footer
    app
    color="grey darken-3"
    absolute
  >
    <v-row
      no-gutters
      justify="center"
      align="center"
    >
      <div class="text-caption text-uppercase grey--text px-2">
        &copy; 2018-{{ new Date().getFullYear() }} ABA Tools, LLC
      </div>
      <div
        v-for="(link, i) in footerLinks"
        :key="'l_'+i"
      >
        <span
          v-if="i > 0"
          class="grey--text"
        >|</span>
        <router-link
          :to="link.link"
          class="text-caption footer-link text-uppercase text-decoration-none px-2"
        >
          {{ link.title }}
        </router-link>
      </div>
      <span
        class="grey--text"
      >|</span>
      <div
        v-for="(link, i) in footerHrefs"
        :key="'h_'+i"
      >
        <span
          v-if="i > 0"
          class="grey--text"
        >|</span>
        <a
          :href="link.link"
          target="_blank"
          class="text-caption footer-link text-uppercase text-decoration-none px-2"
        >
          {{ link.title }}
        </a>
      </div>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'BigFooter',
  data () {
    return {
      footerLinks: [
        {
          title: 'Terms of Service & Privacy Policy',
          link: '/legal'
        }
      ],
      footerHrefs: [
        {
          title: 'Contact Us',
          link: 'https://www.abatools.com/contact-aba-tools/'
        }
      ],
      footerSocial: [
        {
          icon: 'mdi-facebook',
          href: 'https://www.facebook.com/ABA-Tools-108266740780170/'
        },
        {
          icon: 'mdi-twitter',
          href: 'https://twitter.com/AbaTools'
        },
        {
          icon: 'mdi-linkedin',
          href: 'https://www.linkedin.com/company/aba-tools/'
        },
        {
          icon: 'mdi-youtube',
          href: 'https://www.youtube.com/channel/UCdyw3m4XoY5CrP75wlAgiyA/'
        },
        {
          icon: 'mdi-discord',
          href: 'https://discord.gg/muTYmFb'
        }
      ]
    }
  }
}
</script>

<style scoped>
.footer-link {
  /* color: #9E9E9E !important; */
  color: #ffcf00 !important;
}

.footer-link:hover {
  /* color: #ffcf00 !important; */
  text-decoration: underline !important;
}
</style>
