import { isLoggedIn, logOut, setSub, setQuantity, mapPermissions } from '@/shared/utils/auth'
import { createInitials } from '@/shared/utils/user'
import { setOrganizationId } from '@/shared/utils/organization'
import { setImageTags } from '@/shared/utils/library'

import GENUM from '@/shared/enums/enums'
import LENUM from '@/shared/enums/LocalLoad'
// import adminRouter from '@/router/AdminRoutes'
// import appRouter from '@/router/AppRoutes'

const state = {
  apiState: GENUM.INIT,
  apiLocalState: LENUM.INIT,
  isLoggedIn: false,
  details: {},
  permissions: {},
  organization: {}
}
const getters = {
  isAuth: state => !!state.isLoggedIn
}
const actions = {
  async loadEportalUser ({ commit, dispatch }) {
    if (isLoggedIn()) {
      try {
        // eslint-disable-next-line no-undef
        const user = (await axios.get('/user')).data
        if (user.role >= 3) {
          commit('setApiState', GENUM.LOADING)
          commit('setUser', user)
          // eslint-disable-next-line no-undef
          const organization = (await axios.get('/api/v1/user-data')).data
          // eslint-disable-next-line no-undef
          const permissions = (await axios.get(`/api/v1/user/show-permissions/${user.uuid}`)).data
          commit('setUserOrganization', organization)
          commit('setUserPermissions', mapPermissions(permissions))
          setSub(organization.o_subscription)
          setQuantity(organization.o_quantity)
          setOrganizationId(organization.o_uuid)
          commit('setUserOrganizationInitials', createInitials(organization.o_name, 'orgInitials'))
          commit('setUserInitials', createInitials(user.name, 'userInitials'))
          commit('setLoggedIn', true)
          if (localStorage.imageTags) {
            if (localStorage.imageTags.length === 0) {
              // eslint-disable-next-line no-undef
              const images = (await axios.get('/api/v1/image-tags')).data
              setImageTags(images)
            }
          } else {
            // eslint-disable-next-line no-undef
            const images = (await axios.get('/api/v1/image-tags')).data
            setImageTags(images)
          }
          this.dispatch('organization/loadOrganization')
          commit('setApiState', GENUM.LOADED)
        } else {
          commit('setApiState', GENUM.ERROR)
          dispatch('logout')
        }
      } catch (error) {
        commit('setApiState', GENUM.ERROR)
        dispatch('logout')
      }
    }
  },
  async loadUser ({ commit, dispatch }) {
    if (isLoggedIn()) {
      commit('setApiState', GENUM.ERROR)
      try {
        commit('setApiState', GENUM.LOADING)
        // eslint-disable-next-line no-undef
        const user = (await axios.get('/user')).data
        // eslint-disable-next-line no-undef
        const organization = (await axios.get('/api/v1/user-data')).data
        // eslint-disable-next-line no-undef
        const permissions = (await axios.get(`/api/v1/user/show-permissions/${user.uuid}`)).data
        commit('setUser', user)
        commit('setUserOrganization', organization)
        commit('setUserPermissions', mapPermissions(permissions))
        setSub(organization.o_subscription)
        setQuantity(organization.o_quantity)
        setOrganizationId(organization.o_uuid)
        commit('setUserOrganizationInitials', createInitials(organization.o_name, 'orgInitials'))
        commit('setUserInitials', createInitials(user.name, 'userInitials'))
        commit('setLoggedIn', true)
        if (localStorage.imageTags) {
          if (localStorage.imageTags.length === 0) {
            // eslint-disable-next-line no-undef
            const images = (await axios.get('/api/v1/image-tags')).data
            setImageTags(images)
          }
        } else {
          // eslint-disable-next-line no-undef
          const images = (await axios.get('/api/v1/image-tags')).data
          setImageTags(images)
        }
        this.dispatch('organization/loadOrganization')
        commit('setApiState', GENUM.LOADED)
      } catch (error) {
        commit('setApiState', GENUM.ERROR)
        dispatch('logout')
      }
    }
  },
  async updateUser ({ commit, dispatch }) {
    if (isLoggedIn()) {
      try {
        commit('apiLocalState', LENUM.LOADING)
        // eslint-disable-next-line no-undef
        const user = (await axios.get('/user')).data
        // eslint-disable-next-line no-undef
        const organization = (await axios.get('/api/v1/user-data')).data
        commit('setUser', user)
        commit('setUserInitials', createInitials(user.name, 'userInitials'))
        commit('setUserOrganization', organization)
        setSub(organization.o_subscription)
        setQuantity(organization.o_quantity)
        setOrganizationId(organization.o_uuid)
        commit('setUserOrganizationInitials', createInitials(organization.o_name, 'orgInitials'))
        commit('setLoggedIn', true)
        commit('apiLocalState', LENUM.LOADED)
      } catch (error) {
        commit('apiLocalState', LENUM.ERROR)
        dispatch('logout')
      }
    }
  },
  logout ({ commit }) {
    commit('setUser', {})
    commit('setUserPermissions', {})
    commit('setUserOrganization', {})
    commit('setUserOrganizationInitials', '')
    commit('setUserInitials', '')
    commit('setLoggedIn', false)
    // eslint-disable-next-line no-undef
    axios.post('/logout')
    logOut()

    // const host = window.location.host
    // const parts = host.split('.')
    // const domainLength = 3 // route1.example.com => domain length = 3
    // console.log('host ' + host)
    // console.log('parts ' + parts[0])
    // if (parts.length === (domainLength - 1) || parts[0] === 'www') {
    //   appRouter.push({ name: 'login' })
    // } else if (parts[0] === 'app') {
    //   appRouter.push({ name: 'login' })
    // } else if (parts[0] === 'learning') {
    //   appRouter.push({ name: 'login' })
    // } else if (parts[0] === 'eportal') {
    //   console.log('should be here')
    //   adminRouter.push({ name: 'eportal-login' })
    // } else {
    // // If you want to do something else just comment the line below
    //   appRouter.push({ name: 'login' })
    // }

    // adminRouter.push({ name: 'eportal-login' })
    // appRouter.push({ name: 'login' })
  }
}
const mutations = {
  setApiState (state, payload) {
    state.apiState = payload
  },
  apiLocalState (state, payload) {
    state.apiLocalState = payload
  },
  setUser (state, payload) {
    state.details = payload
  },
  setUserOrganization (state, payload) {
    state.organization = payload
  },
  setUserPermissions (state, payload) {
    state.permissions = payload
  },
  setUserOrganizationInitials (state, payload) {
    state.orgInitials = payload
  },
  setUserInitials (state, payload) {
    state.initials = payload
  },
  setLoggedIn (state, payload) {
    state.isLoggedIn = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
