import LENUM from '@/shared/enums/LocalLoad'
import { isLoggedIn } from '../../shared/utils/auth'
import axios from 'axios'
import { shuffleArray } from '@/shared/utils/sorts'
import { cloneDeep } from 'lodash'

const state = {
  apiState: LENUM.INIT,
  skill: {},
  allSkills: [],
  allLearnerSkills: [],
  learnerSkills: [],
  learnerAssessmentSkills: [],
  allAssessmentSkills: [],
  allLearnerAssessmentSkills: [],
  currentAssessmentSkill: {},
  lastSkillAddedId: ''
}

const getters = {
  allSkills: state => state.allSkills,
  allAssessmentSkills: state => state.allAssessmentSkills,
  lastSkillAddedId: state => state.lastSkillAddedId,
  // setCurrentSkill: (state) => (id) => { return state.allSkills.filter((skill) => skill.id === id)[0] },
  currentSkill: state => state.skill,
  currentAssessmentSkill: state => state.currentAssessmentSkill,
  allLearnerSkills: state => state.allLearnerSkills,
  allLearnerAssessmentSkills: state => state.allLearnerAssessmentSkills,
  learnerSkills: state => state.learnerSkills,
  learnerAssessmentSkills: state => state.learnerAssessmentSkills
}

const actions = {
  async fetchAssessmentSkills ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = await axios.get('/api/v1/assessment-skills')
        // console.log('fetching assessment skills')
        commit('setAssessmentSkills', response.data)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },
  async fetchSkills ({ commit }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = await axios.get('/api/v1/skills')
        // console.log('fetching custom skills')
        commit('setSkills', response.data)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },

  async setCurrentSkill ({ commit, dispatch, state }, id) {
    if (isLoggedIn()) {
      if (state.allSkills.length === 0) {
        await dispatch('fetchSkills')
      }
      const skill = cloneDeep(state.allSkills.filter((skill) => skill.id === id)[0])
      commit('setCurrentAssessmentSkill', {})
      commit('setCurrentSkill', skill)
    }
  },

  async setCurrentAssessmentSkill ({ commit, dispatch, state }, id) {
    if (isLoggedIn()) {
      if (state.allAssessmentSkills.length === 0) {
        await dispatch('fetchAssessmentSkills')
      }
      const skill = cloneDeep(state.allAssessmentSkills.filter((skill) => skill.id === id)[0])
      commit('setCurrentSkill', {})
      commit('setCurrentAssessmentSkill', skill)
    }
  },

  async addSkill ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = await axios.post('/api/v1/custom-skills/save', data)
        data.id = response.data
        await commit('addSkill', data)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Skill has been saved.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to save skill.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },
  async editSkill ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.patch('/api/v1/custom-skills/update', data)
        commit('editSkill', data)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Skill has been saved.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        // console.log(error)
        commit('setApiState', LENUM.ERROR)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to save skill.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },
  async duplicateCustomSkill ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = (await axios.post('/api/v1/custom-skills/duplicate', data)).data
        commit('duplicateCustomSkill', response)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Skill has been duplicated.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        // console.log(error)
        commit('setApiState', LENUM.ERROR)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to duplicate skill.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },
  async duplicateAssessmentSkill ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = (await axios.post('/api/v1/assessment-skills/duplicate', data)).data
        commit('duplicateAssessmentSkill', response)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Skill has been duplicated.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to duplicate skill.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },
  //
  async eportalAddAssessmentSkill ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = await axios.post('/api/v1/eportal/assessment-skills/save', data)
        data.id = response.data
        commit('addAssessmentSkillEportal', data)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Skill has been saved.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to save skill.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },
  async eportalEditAssessmentSkill ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.patch(`/api/v1/eportal/assessment-skills/${data.id}/update`, data)
        commit('editAssessmentSkillEportal', data)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Skill has been saved.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        // console.log(error)
        commit('setApiState', LENUM.ERROR)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to save skill.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },
  async duplicateAssessmentSkillEportal ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = (await axios.post('/api/v1/eportal/assessment-skills/duplicate', data)).data
        commit('duplicateAssessmentSkillEportal', response)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Skill has been duplicated.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to duplicate skill.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },
  async destroyAssessmentSkillEportal ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.post(`/api/v1/eportal/assessment-skills/${data.skill_id}/delete`)
        commit('deleteAssessmentSkillEportal', data)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Skill has been deleted.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        // console.log(error)
        commit('setApiState', LENUM.ERROR)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to delete skill.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },

  async destroySkill ({ commit, dispatch }, data) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        await axios.post(`/api/v1/custom-skills/${data.skill_id}/delete`)
        commit('deleteSkill', data)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-check',
          text: 'Success: Skill has been deleted.',
          color: 'success darken-2'
        }, { root: true })
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        // console.log(error)
        commit('setApiState', LENUM.ERROR)
        dispatch('snackbar/setSnackbar', {
          icon: 'mdi-alert',
          text: 'Error: Unable to delete skill.',
          color: 'error darken-2'
        }, { root: true })
      }
    }
  },
  async fetchAllLearnerSkills ({ commit, dispatch, getters }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        // if (getters.allSkills.length === 0) {
        // console.log('fetching all skills from within all learner skills')
        //   await dispatch('fetchSkills')
        // }
        const response = await axios.get('/api/v1/learner/skills')
        // console.log('fetching learner custom skills')
        commit('setAllLearnerSkills', response.data)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },

  async fetchAllLearnerAssessmentSkills ({ commit, dispatch, getters }) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        // if (getters.allAssessmentSkills.length === 0) {
        // console.log('fetching all assessment skills from within all learner assessment skills')
        //   await dispatch('fetchAssessmentSkills')
        // }
        const response = await axios.get('/api/v1/learner/assessment-skills')
        // console.log('fetching learner assessment skills')
        commit('setAllLearnerAssessmentSkills', response.data)
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        commit('setApiState', LENUM.ERROR)
      }
    }
  },

  async setLearnerSkills ({ commit, dispatch, getters }, id) {
    if (isLoggedIn()) {
      commit('setApiState', LENUM.LOADING)
      if (getters.allLearnerSkills.length === 0) {
        await dispatch('fetchAllLearnerSkills')
      }
      commit('setLearnerSkills', id)
      commit('setApiState', LENUM.LOADED)
    }
  },

  async setLearnerAssessmentSkills ({ commit, dispatch, getters }, id) {
    if (isLoggedIn()) {
      commit('setApiState', LENUM.LOADING)
      if (getters.allLearnerAssessmentSkills.length === 0) {
        await dispatch('fetchAllLearnerAssessmentSkills')
      }
      commit('setLearnerAssessmentSkills', id)
      commit('setApiState', LENUM.LOADED)
    }
  },

  async addLearnerSkill ({ commit, dispatch, rootGetters }, skill) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = await axios.post('/api/v1/learner/skills/add', skill)
        const data = {
          response: response.data,
          skill: skill
        }
        const exists = state.allLearnerSkills
          .filter((learner) => learner.learner_id === data.skill.learner_id)
          .filter(skill => skill.skill_id === data.skill.skill_id)
        if (exists.length > 0) {
          dispatch('snackbar/setSnackbar', {
            text: `Exists: Learner already has "${data.skill.title}".`,
            icon: 'mdi-alert',
            color: 'error darken-2'
          }, { root: true })
        } else {
          commit('addLearnerSkill', data)
          commit('setLearnerSkills', data.skill.learner_id)
          dispatch('snackbar/setSnackbar', {
            text: `Success: "${data.skill.title}" assigned to learner!`,
            icon: 'mdi-check',
            color: 'success darken-2'
          }, { root: true })
        }
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        dispatch('snackbar/setSnackbar', {
          text: 'Error: Could not add skill to learner.',
          icon: 'mdi-alert',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },

  async addLearnerAssessmentSkill ({ commit, dispatch, rootGetters }, skill) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = await axios.post('/api/v1/learner/assessment-skills/add', skill)
        const data = {
          response: response.data,
          skill: skill
        }
        const exists = state.allLearnerAssessmentSkills
          .filter((learner) => learner.learner_id === data.skill.learner_id)
          .filter(skill => skill.skill_id === data.skill.skill_id)
        if (exists.length > 0) {
          dispatch('snackbar/setSnackbar', {
            text: `Exists: Learner already has "${data.skill.title}".`,
            icon: 'mdi-alert',
            color: 'error darken-2'
          }, { root: true })
        } else {
          commit('addLearnerAssessmentSkill', data)
          commit('setLearnerAssessmentSkills', data.skill.learner_id)
          dispatch('snackbar/setSnackbar', {
            text: `Success: "${data.skill.title}" assigned to learner!`,
            icon: 'mdi-check',
            color: 'success darken-2'
          }, { root: true })
        }
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        dispatch('snackbar/setSnackbar', {
          text: 'Error: Could not add skill to learner.',
          icon: 'mdi-alert',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },

  async delLearnerSkill ({ commit, dispatch, rootGetters }, skill) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = await axios.post('/api/v1/learner/skills/del', skill)
        const data = {
          response: response.data,
          skill: skill
        }
        const exists = state.allLearnerSkills
          .filter((learner) => learner.learner_id === data.skill.learner_id)
          .filter(skill => skill.skill_id === data.skill.skill_id)
        if (exists.length > 0) {
          dispatch('snackbar/setSnackbar', {
            text: `Success: Unassigned "${data.skill.title}" from learner.`,
            icon: 'mdi-check',
            color: 'success darken-2'
          }, { root: true })
          commit('delLearnerSkill', data)
          commit('setLearnerSkills', data.skill.learner_id)
        } else {
          dispatch('snackbar/setSnackbar', {
            text: `"${data.skill.title}" is not assigned to learner.`,
            icon: 'mdi-alert',
            color: 'error darken-2'
          }, { root: true })
        }
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        dispatch('snackbar/setSnackbar', {
          text: 'Error: Could not remove skill from learner.',
          icon: 'mdi-alert',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },

  async delLearnerAssessmentSkill ({ commit, dispatch, rootGetters }, skill) {
    if (isLoggedIn()) {
      try {
        commit('setApiState', LENUM.LOADING)
        const response = await axios.post('/api/v1/learner/assessment-skills/del', skill)
        const data = {
          response: response.data,
          skill: skill
        }
        const exists = state.allLearnerAssessmentSkills
          .filter((learner) => learner.learner_id === data.skill.learner_id)
          .filter(skill => skill.skill_id === data.skill.skill_id)
        if (exists.length > 0) {
          dispatch('snackbar/setSnackbar', {
            text: `Success: Unassigned "${data.skill.title}" from learner.`,
            icon: 'mdi-check',
            color: 'success darken-2'
          }, { root: true })
          commit('delLearnerAssessmentSkill', data)
          commit('setLearnerAssessmentSkills', data.skill.learner_id)
        } else {
          dispatch('snackbar/setSnackbar', {
            text: `"${data.skill.title}" is not assigned to learner.`,
            icon: 'mdi-alert',
            color: 'error darken-2'
          }, { root: true })
        }
        commit('setApiState', LENUM.LOADED)
      } catch (error) {
        dispatch('snackbar/setSnackbar', {
          text: 'Error: Could not remove skill from learner.',
          icon: 'mdi-alert',
          color: 'error darken-2'
        }, { root: true })
        commit('setApiState', LENUM.ERROR)
      }
    }
  },

  shuffleCurrentSkill ({ getters, commit }) {
    if (isLoggedIn()) {
      const customSkillIsEmpty = Object.keys(getters.currentSkill).length === 0
      const assessmentSkillIsEmpty = Object.keys(getters.currentAssessmentSkill).length === 0
      if (getters.currentSkill && !customSkillIsEmpty) {
        const shuffledCustomSkill = cloneDeep(getters.currentSkill)
        shuffledCustomSkill.nodes = JSON.parse(shuffledCustomSkill.nodes)
        shuffledCustomSkill.nodes = shuffleArray(shuffledCustomSkill.nodes)
        shuffledCustomSkill.nodes = JSON.stringify(shuffledCustomSkill.nodes)
        commit('shuffleCurrentCustomSkill', shuffledCustomSkill)
      }

      if (getters.currentAssessmentSkill && !assessmentSkillIsEmpty) {
        const shuffledAssessmentSkill = cloneDeep(getters.currentAssessmentSkill)
        shuffledAssessmentSkill.nodes = JSON.parse(shuffledAssessmentSkill.nodes)
        shuffledAssessmentSkill.nodes = shuffleArray(shuffledAssessmentSkill.nodes)
        shuffledAssessmentSkill.nodes = JSON.stringify(shuffledAssessmentSkill.nodes)
        commit('shuffleCurrentAssessmentSkill', shuffledAssessmentSkill)
      }
    }
  }
}

const mutations = {
  setApiState (state, status) {
    state.apiState = status
  },
  setCurrentSkill (state, skill) {
    state.skill = skill
  },
  setCurrentAssessmentSkill (state, skill) {
    state.currentAssessmentSkill = skill
  },
  setAssessmentSkills (state, skills) {
    state.allAssessmentSkills = skills
  },
  setSkills (state, skills) {
    state.allSkills = skills
  },
  addSkill (state, data) {
    const skill = {
      id: data.id,
      instructions: data.instructions ? data.instructions : null,
      public: 0,
      nodes: data.nodes,
      title: data.title
    }
    state.lastSkillAddedId = data.id
    state.allSkills.push(skill)
  },
  addAssessmentSkillEportal (state, data) {
    const skill = {
      id: data.id,
      instructions: data.instructions ? data.instructions : null,
      public: 0,
      nodes: data.nodes,
      title: data.title
    }
    state.allAssessmentSkills.push(skill)
  },
  editSkill (state, data) {
    const index = state.allSkills.findIndex(skill => skill.id === data.id)
    const skill = {
      id: data.id,
      instructions: data.instructions ? data.instructions : null,
      public: 0,
      nodes: data.nodes,
      title: data.title
    }
    if (index !== -1) {
      state.allSkills.splice(index, 1, skill)
    }
  },
  editAssessmentSkillEportal (state, data) {
    const index = state.allAssessmentSkills.findIndex(skill => skill.id === data.id)
    const skill = {
      id: data.id,
      instructions: data.instructions ? data.instructions : null,
      public: 0,
      nodes: data.nodes,
      title: data.title
    }
    if (index !== -1) {
      state.allAssessmentSkills.splice(index, 1, skill)
    }
  },
  duplicateCustomSkill (state, data) {
    state.allSkills.push(data)
  },
  duplicateAssessmentSkill (state, data) {
    state.allSkills.push(data)
  },
  duplicateAssessmentSkillEportal (state, data) {
    state.allAssessmentSkills.push(data)
  },
  deleteAssessmentSkillEportal (state, data) {
    const index = state.allAssessmentSkills
      .findIndex(skill => skill.id === data.skill_id)
    if (index !== -1) {
      state.allAssessmentSkills.splice(index, 1)
    }
  },
  deleteSkill (state, data) {
    const index = state.allSkills
      .findIndex(skill => skill.id === data.skill_id)
    if (index !== -1) {
      state.allSkills.splice(index, 1)
    }
  },
  setAllLearnerSkills (state, skills) {
    state.allLearnerSkills = skills
  },
  setAllLearnerAssessmentSkills (state, skills) {
    state.allLearnerAssessmentSkills = skills
  },
  setLearnerSkills (state, id) {
    const skills = state.allLearnerSkills.filter(skill => skill.learner_id === id)
    state.learnerSkills = skills
  },
  setLearnerAssessmentSkills (state, id) {
    const skills = state.allLearnerAssessmentSkills.filter(skill => skill.learner_id === id)
    state.learnerAssessmentSkills = skills
  },
  addLearnerSkill (state, data) {
    state.allLearnerSkills.push(data.skill)
  },
  delLearnerSkill (state, data) {
    const index = state.allLearnerSkills
      .findIndex(skill => skill.learner_id === data.skill.learner_id && skill.skill_id === data.skill.skill_id)
    if (index !== -1) {
      state.allLearnerSkills.splice(index, 1)
    }
  },
  addLearnerAssessmentSkill (state, data) {
    state.allLearnerAssessmentSkills.push(data.skill)
  },
  delLearnerAssessmentSkill (state, data) {
    const index = state.allLearnerAssessmentSkills
      .findIndex(skill => skill.learner_id === data.skill.learner_id && skill.skill_id === data.skill.skill_id)
    if (index !== -1) {
      state.allLearnerAssessmentSkills.splice(index, 1)
    }
  },
  shuffleCurrentCustomSkill (state, data) {
    state.skill = data
  },
  shuffleCurrentAssessmentSkill (state, data) {
    state.currentAssessmentSkill = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
